import {
  SuspenseQueryHookOptions,
  useSuspenseQuery,
} from '@apollo/client/react';
import { ResultOf, VariablesOf } from '@graphql-typed-document-node/core';
import { gql } from '../gql';

export const ProjectQuery = gql(`
  query ProjectQuery {
    project {
      id
      enabledAddOns
      apiEndpoint
      apiKey
      hideAlliBranding
      useCautionMessage
      popularLlmApps {
        llmApps {
          id
          name
          description
          type
          category
          icon

          campaign {
            id
            popupUrl
          }

          singleActionApp {
            id
            publicToken
          }
        }
      }
      recommendedQuestions
      useCustomAssistantEntryLlmApp

      oktaOidcInfo {
        clientId
        issuerUrl
      }

      userEntraIdInfo {
        loginUrl
        samlRequest
      }

      appCollectionSettings {
        companyName
        themeColor

        companyLogo {
          id
          url
        }
      }

      assistantEntryLlmApp {
        campaign {
          id
        }
      }
    }
  }

`);

export interface UseProjectQueryOptions
  extends SuspenseQueryHookOptions<
    ResultOf<typeof ProjectQuery>,
    VariablesOf<typeof ProjectQuery>
  > {}

export const useProjectQuery = (options: UseProjectQueryOptions) => {
  return useSuspenseQuery(ProjectQuery, options);
};
