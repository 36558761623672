import { ChatFormIntentSelector } from '@allganize/alli-sdk-ui';
import { IframeContext } from '@allganize/react-iframe';
import { css } from '@emotion/react';
import {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useController } from 'react-hook-form';
import { useLocation, useMatch } from 'react-router-dom';
import { assistantIntentSelectorOptions } from './assistant-intent-selector-options';
import {
  AssistantIntentSelectorProps,
  IntentValueType,
} from './assistant-intent-selector-types';
import { analytics } from '../../analytics';

const MemoizedIntentSelector = memo(ChatFormIntentSelector);

export const AssistantIntentSelector = forwardRef<
  HTMLButtonElement,
  AssistantIntentSelectorProps
>(
  (
    { control, conversationId, llmAppId, onClick, onChange, ...others },
    ref,
  ) => {
    const routeName = useMatch('/') !== null ? 'home' : 'conversation-detail';
    const { state } = useLocation();
    const { field } = useController({
      control,
      name: 'intent',
      defaultValue: state?.intent,
    });
    const selectedIntent =
      field.value && field.value in assistantIntentSelectorOptions
        ? assistantIntentSelectorOptions[field.value]
        : assistantIntentSelectorOptions.default;

    const messengerIframe = useContext(IframeContext);
    const [minimize, setMinimize] = useState(
      messengerIframe.window.innerWidth < 400,
    );
    const calculateMinimize = useCallback(() => {
      setMinimize(messengerIframe.window.innerWidth < 400);
    }, [messengerIframe]);

    useEffect(() => {
      messengerIframe.window.addEventListener('resize', calculateMinimize);
      return () => {
        messengerIframe.window.removeEventListener('resize', calculateMinimize);
      };
    }, [calculateMinimize, messengerIframe]);

    return (
      <MemoizedIntentSelector
        tooltipProps={{
          placement: 'top-start',
        }}
        {...field}
        {...others}
        onClick={e => {
          onClick?.(e);
          analytics.track(`${routeName}::chat-input__intent-selector.click`, {
            conversationId: conversationId,
            appId: llmAppId,
          });
        }}
        onChange={value => {
          field.onChange(value);
          analytics.track(
            `${routeName}::chat-input__intent-selector-intent.click`,
            {
              conversationId,
              appId: llmAppId,
              intent: (value ?? 'default') as IntentValueType,
            },
          );
        }}
        value={selectedIntent}
        options={Object.values(assistantIntentSelectorOptions)}
        minimize={minimize}
        ref={ref}
        css={css`
          position: absolute;
          z-index: -1;
          top: 0;
          left: 8px;
          margin: 0;
          transform: translateY(-100%);
        `}
      />
    );
  },
);
