import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { HTMLAttributes } from 'react';
import { useIntl } from 'react-intl';

interface KnowledgeBaseCountBadgeProps extends HTMLAttributes<HTMLDivElement> {
  count?: number;
}

export const KnowledgeBaseCountBadge = ({
  count,
  ...others
}: KnowledgeBaseCountBadgeProps) => {
  const intl = useIntl();
  const theme = useTheme();

  if (!count) {
    return null;
  }
  return (
    <div
      css={css`
        background-color: ${theme.palette.unstable_background.information
          .default};
        color: ${theme.palette.foreground.inverse};
        border-radius: ${theme.radius.round}px;
      `}
      {...others}
    >
      <Text
        variant="body12"
        aria-label={intl.formatMessage(
          {
            id: 'knowledge-base.upload-button__upload-count-badge.aria-label',
            defaultMessage: '{count} knowledge bases are selected',
            description:
              'assistant selected knowledge bases count badge aria label',
          },
          { count },
        )}
        css={css`
          height: 20px;
          min-width: 20px;
          padding: 0 6px;
          text-align: center;
        `}
      >
        {count}
      </Text>
    </div>
  );
};
