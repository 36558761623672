import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import {
  AlliGraphQLError,
  useFormatErrorMessage,
} from '@allganize/utils-graphql';
import { MutationFunctionOptions, useMutation } from '@apollo/client/react';
import { ResultOf, VariablesOf } from '@graphql-typed-document-node/core';
import { gql } from '../gql';

const UserSendFeedbackMutation = gql(`
  mutation UserSendFeedbackMutation(
    $chatId: ID!
    $conversationId: ID!
    $rating: Boolean
    $mrcInfoId: String
  ) {
    userSendFeedback(
      chatId: $chatId
      conversationId: $conversationId
      rating: $rating
      mrcInfoId: $mrcInfoId
    ) {
      chat {
        ...on BotChat {
          id
          rating
        }

        ...on CarouselChat {
          id

          chatMrcInfos {
            id
            rating
          }
        }
      }

      errors {
        key
        message
        field
        info
      }
    }
  }
`);

const UserSendFeedbackImproverMutation = gql(`
  mutation UserSendFeedbackImproverMutation(
    $chatId: ID!
    $conversationId: ID!
    $feedbackSelection: [UserAnswerFeedbackSelection]
    $suggestedAnswer: String
  ) {
    userSendFeedbackImprover(
      chatId: $chatId
      conversationId: $conversationId
      feedbackSelection: $feedbackSelection
      suggestedAnswer: $suggestedAnswer
    ) {
      ok

      errors {
        key
        message
        field
        info
      }
    }
  }
`);

export const useUserFeedback = () => {
  const formatErrorMessage = useFormatErrorMessage();
  const [sendFeedbackMutate] = useMutation(UserSendFeedbackMutation);
  const [sendFeedbackImproverMutate] = useMutation(
    UserSendFeedbackImproverMutation,
  );

  const sendFeedback = async (
    options: Pick<
      MutationFunctionOptions<
        ResultOf<typeof UserSendFeedbackMutation>,
        VariablesOf<typeof UserSendFeedbackMutation>
      >,
      'variables' | 'optimisticResponse'
    >,
  ) => {
    const result = await sendFeedbackMutate(options);

    if (
      (result.data?.userSendFeedback?.errors ?? []).findIndex(
        err => err?.key === UserQueryTypes.MallyError.RACE_CONDITION,
      ) >= 0
    ) {
      return sendFeedback(options);
    }

    return result;
  };

  const sendFeedbackImprover = async (
    variables: VariablesOf<typeof UserSendFeedbackImproverMutation>,
  ) => {
    const result = await sendFeedbackImproverMutate({ variables });

    if (
      (result.data?.userSendFeedbackImprover?.errors ?? []).findIndex(
        err => err?.key === UserQueryTypes.MallyError.RACE_CONDITION,
      ) >= 0
    ) {
      await sendFeedbackImprover(variables);
      return;
    }

    if (result.data?.userSendFeedbackImprover?.ok) {
      return;
    }

    if (result.data?.userSendFeedbackImprover?.errors?.length) {
      throw new AlliGraphQLError(result.data.userSendFeedbackImprover.errors);
    }

    throw new Error(formatErrorMessage());
  };

  return { sendFeedback, sendFeedbackImprover };
};
