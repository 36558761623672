import { ChatStackItemProps } from '@allganize/alli-chat-base';
import { ResultOf } from '@graphql-typed-document-node/core';
import { FunctionComponent } from 'react';
import { GeneratingState } from '../generating-state';
import { gql } from '../gql';
import { IntermediateSteps } from '../intermediate-steps';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AnswerProgress_ChatFragment = gql(`
  fragment AnswerProgress_ChatFragment on Chat {
    ...on BotChat {
      id

      intermediateSteps {
        id
      }

      ...GeneratingState_BotChatFragment
      ...IntermediateSteps_BotChatFragment
    }
  }
`);

export interface AnswerProgressProps extends ChatStackItemProps {
  chat: ResultOf<typeof AnswerProgress_ChatFragment>;
}

export const AnswerProgress: FunctionComponent<AnswerProgressProps> = props => {
  const { chat, ...other } = props;

  if (chat.__typename !== 'BotChat') {
    return null;
  }

  if (chat.intermediateSteps?.length) {
    return <IntermediateSteps chat={chat} {...other} />;
  }

  return <GeneratingState chat={chat} {...other} />;
};
