import { ChatStackItem, ChatStackItemProps } from '@allganize/alli-chat-base';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
} from '@allganize/ui-accordion';
import { IcArrowExpandMore } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FragmentType, gql, readFragment } from '../gql';
import { IntermediateStep } from './intermediate-step';
import { IntermediateStepsSummary } from './intermediate-steps-summary';

export const IntermediateSteps_BotChatFragment = gql(`
  fragment IntermediateSteps_BotChatFragment on BotChat {
    id

    intermediateSteps {
      id
      ...IntermediateStep_IntermediateStepFragment
    }

    ...IntermediateStepsSummary_BotChatFragment
  }
`);

export interface IntermediateStepsProps extends ChatStackItemProps {
  chat: FragmentType<typeof IntermediateSteps_BotChatFragment>;
}

export const IntermediateSteps: FunctionComponent<
  IntermediateStepsProps
> = props => {
  const { chat: chatProp, ...other } = props;
  const chat = readFragment(IntermediateSteps_BotChatFragment, chatProp);

  if (!chat.intermediateSteps?.length) {
    return null;
  }

  return (
    <ChatStackItem {...other}>
      <div
        css={css`
          padding: 0 12px;
        `}
      >
        <Accordion
          css={css`
            background-color: transparent;
          `}
        >
          <AccordionSummary
            css={css`
              padding: 8px 0;
              min-height: 44px;
              justify-content: flex-start;

              .${accordionSummaryClasses.content} {
                margin: 0;
                flex-grow: 0;
              }
            `}
            expandIcon={<IcArrowExpandMore />}
          >
            <IntermediateStepsSummary chat={chat} />
          </AccordionSummary>

          <AccordionDetails
            css={css`
              padding: 8px 0;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 16px;
              `}
            >
              {chat.intermediateSteps.map(step => {
                return <IntermediateStep key={step.id} step={step} />;
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </ChatStackItem>
  );
};
