import { informationIconMap } from '@allganize/alli-app-market-icons';
import { Truncate } from '@allganize/truncate';
import { ButtonBase, ButtonBaseProps } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { ResultOf } from '@graphql-typed-document-node/core';
import { FunctionComponent, useState } from 'react';
import { FragmentType, gql, readFragment } from '../gql';

export const LLMAppCarouselItem_LLMAppFragment = gql(`
  fragment LLMAppCarouselItem_LLMAppFragment on LLMApp {
    id
    icon
    name
    description
  }
`);

export interface LLMAppCarouselItemProps
  extends Omit<ButtonBaseProps, 'onClick'> {
  app: FragmentType<typeof LLMAppCarouselItem_LLMAppFragment>;
  index: number;
  onClick?(
    app: ResultOf<typeof LLMAppCarouselItem_LLMAppFragment>,
    index: number,
  ): Promise<void>;
}

export const LLMAppCarouselItem: FunctionComponent<
  LLMAppCarouselItemProps
> = props => {
  const { app: appProp, index, onClick, ...other } = props;
  const app = readFragment(LLMAppCarouselItem_LLMAppFragment, appProp);
  const theme = useTheme();
  const { icon, name, description } = app;
  const Icon = icon && (informationIconMap as any)[icon];
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      await onClick?.(app, index);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ButtonBase
      data-testid="recommended-app"
      css={css`
        width: 184px;
        height: 160px;
        padding: 24px 12px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        border: 1px solid ${theme.palette.border.divider.default};
        border-radius: ${theme.radius.sm}px;
        background-color: ${theme.palette.unstable_background.white};
      `}
      onClick={handleClick}
      {...other}
    >
      <div
        css={css`
          width: 20px;
          height: 20px;
          color: ${theme.palette.foreground.primary.default};
        `}
      >
        {loading ? <CircularProgress size="sm" /> : Icon && <Icon />}
      </div>
      <Truncate clamp={2}>
        <Text variant="title14">{name}</Text>
      </Truncate>
      <Truncate clamp={2}>
        <Text
          variant="body12"
          css={css`
            color: ${theme.palette.foreground.secondary};
          `}
        >
          {description}
        </Text>
      </Truncate>
    </ButtonBase>
  );
};
