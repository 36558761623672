import { useWindow } from '@allganize/hooks';
import { Avatar } from '@allganize/ui-avatar';
import { ListItem, ListItemAvatar, ListItemText } from '@allganize/ui-list';
import { HighlightedText, SelectField } from '@allganize/ui-select';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FormatOptionLabelMeta } from 'react-select';
import {
  AgentSelectOption,
  ContactAgentFormValues,
} from './use-contact-agent-form';
import { useContactAgentFormAgent } from './use-contact-agent-form-agent';

const formatOptionLabel = (
  option: AgentSelectOption,
  formatOptionLabelMeta: FormatOptionLabelMeta<AgentSelectOption>,
) => {
  const avatar = (
    <ListItemAvatar>
      <Avatar size="smallest" src={option.avatar} alt={option.label}>
        {option.label.charAt(0)}
      </Avatar>
    </ListItemAvatar>
  );

  if (formatOptionLabelMeta.context !== 'menu') {
    return (
      <ListItem component="div" disablePadding>
        {avatar}

        <ListItemText
          css={css`
            padding: 0;
            word-break: break-all;
          `}
          primary={option.label}
        />
      </ListItem>
    );
  }

  return (
    <>
      {avatar}

      <ListItemText
        css={css`
          word-break: break-all;
        `}
        primary={
          <HighlightedText
            text={option.label}
            query={formatOptionLabelMeta.inputValue}
            options={{ insideWords: true, findAllOccurrences: true }}
          />
        }
      />
    </>
  );
};

export const AgentField: FunctionComponent = () => {
  const { ref: rootRef, window: win } = useWindow();
  const { control } = useFormContext<ContactAgentFormValues>();
  const {
    field: { ref: fieldRef, ...field },
    fieldState,
  } = useController({
    control,
    name: 'agent',
  });
  const { isLoading, options } = useContactAgentFormAgent();

  return (
    <SelectField
      {...field}
      selectRef={fieldRef}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      options={options}
      fullWidth
      label={
        <FormattedMessage
          id="agent"
          defaultMessage="{count, plural, one {Agent} other {Agents}}"
          description="contact agent by email form agent field label"
          values={{ count: 1 }}
        />
      }
      ref={rootRef}
      SelectProps={{
        formatOptionLabel,
        isClearable: true,
        isLoading,
        menuPortalTarget: win?.document.body,
      }}
    />
  );
};
