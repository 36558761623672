/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment AnswerProgress_ChatFragment on Chat {\n    ...on BotChat {\n      id\n\n      intermediateSteps {\n        id\n      }\n\n      ...GeneratingState_BotChatFragment\n      ...IntermediateSteps_BotChatFragment\n    }\n  }\n": types.AnswerProgress_ChatFragmentFragmentDoc,
    "\n  fragment GeneratingErrorState_ChatFragment on Chat {\n    ...on BotChat {\n      id\n      createdAt\n      completeState\n    }\n  }\n": types.GeneratingErrorState_ChatFragmentFragmentDoc,
    "\n  fragment GeneratingState_BotChatFragment on BotChat {\n    id\n    generatingState\n  }\n": types.GeneratingState_BotChatFragmentFragmentDoc,
    "\n  fragment IntermediateStep_IntermediateStepFragment on IntermediateStep {\n    id\n    markdownMessage\n    statusMessage\n    completed\n  }\n": types.IntermediateStep_IntermediateStepFragmentFragmentDoc,
    "\n  fragment IntermediateStepsSummary_BotChatFragment on BotChat {\n    id\n    generatingState\n\n    intermediateSteps {\n      id\n      completed\n      statusMessage\n    }\n  }\n": types.IntermediateStepsSummary_BotChatFragmentFragmentDoc,
    "\n  fragment IntermediateSteps_BotChatFragment on BotChat {\n    id\n\n    intermediateSteps {\n      id\n      ...IntermediateStep_IntermediateStepFragment\n    }\n\n    ...IntermediateStepsSummary_BotChatFragment\n  }\n": types.IntermediateSteps_BotChatFragmentFragmentDoc,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AnswerProgress_ChatFragment on Chat {\n    ...on BotChat {\n      id\n\n      intermediateSteps {\n        id\n      }\n\n      ...GeneratingState_BotChatFragment\n      ...IntermediateSteps_BotChatFragment\n    }\n  }\n"): (typeof documents)["\n  fragment AnswerProgress_ChatFragment on Chat {\n    ...on BotChat {\n      id\n\n      intermediateSteps {\n        id\n      }\n\n      ...GeneratingState_BotChatFragment\n      ...IntermediateSteps_BotChatFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GeneratingErrorState_ChatFragment on Chat {\n    ...on BotChat {\n      id\n      createdAt\n      completeState\n    }\n  }\n"): (typeof documents)["\n  fragment GeneratingErrorState_ChatFragment on Chat {\n    ...on BotChat {\n      id\n      createdAt\n      completeState\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment GeneratingState_BotChatFragment on BotChat {\n    id\n    generatingState\n  }\n"): (typeof documents)["\n  fragment GeneratingState_BotChatFragment on BotChat {\n    id\n    generatingState\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment IntermediateStep_IntermediateStepFragment on IntermediateStep {\n    id\n    markdownMessage\n    statusMessage\n    completed\n  }\n"): (typeof documents)["\n  fragment IntermediateStep_IntermediateStepFragment on IntermediateStep {\n    id\n    markdownMessage\n    statusMessage\n    completed\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment IntermediateStepsSummary_BotChatFragment on BotChat {\n    id\n    generatingState\n\n    intermediateSteps {\n      id\n      completed\n      statusMessage\n    }\n  }\n"): (typeof documents)["\n  fragment IntermediateStepsSummary_BotChatFragment on BotChat {\n    id\n    generatingState\n\n    intermediateSteps {\n      id\n      completed\n      statusMessage\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment IntermediateSteps_BotChatFragment on BotChat {\n    id\n\n    intermediateSteps {\n      id\n      ...IntermediateStep_IntermediateStepFragment\n    }\n\n    ...IntermediateStepsSummary_BotChatFragment\n  }\n"): (typeof documents)["\n  fragment IntermediateSteps_BotChatFragment on BotChat {\n    id\n\n    intermediateSteps {\n      id\n      ...IntermediateStep_IntermediateStepFragment\n    }\n\n    ...IntermediateStepsSummary_BotChatFragment\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;