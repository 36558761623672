import { IcClose, IcFile, IcKnowledgeBase } from '@allganize/ui-icons';
import { IconButton } from '@allganize/ui-button';
import { useTheme } from '@allganize/ui-theme';
import { ListItemIcon, ListItemText } from '@allganize/ui-list';
import { Menu, menuClasses, MenuItem, MenuProps } from '@allganize/ui-menu';
import { css } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { KnowledgeBaseSource } from './knowledge-base-upload-button-type-map';
import { KnowledgeBaseCard } from '../knowledge-base-card';
import { KnowledgeBase } from '../types/types';

export interface KnowledgeBaseUploadMenuProps
  extends Omit<MenuProps, 'onSelect'> {
  selectedKnowledgeBases?: KnowledgeBase[];
  onSelect?: (value: KnowledgeBaseSource, ev: React.MouseEvent) => void;
  onDeleteKnowledgeBase?: (knowledgeBaseId: string | number) => void;
}

export const KnowledgeBaseUploadMenu = ({
  selectedKnowledgeBases,
  onSelect,
  onDeleteKnowledgeBase,
  ...others
}: KnowledgeBaseUploadMenuProps) => {
  const theme = useTheme();
  return (
    <Menu
      disableScrollLock
      disableAutoFocusItem
      anchorOrigin={{
        vertical: -4,
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      css={css`
        .${menuClasses.paper} {
          width: 280px;
        }
      `}
      {...others}
    >
      {!!selectedKnowledgeBases?.length && (
        <div
          css={css`
            max-height: 216px;
            overflow-y: auto;
            padding-bottom: 8px;
            border-bottom: 1px solid ${theme.palette.border.divider.default};
          `}
        >
          {selectedKnowledgeBases.map(({ description, ...data }) => (
            <KnowledgeBaseCard
              data={data}
              endAdornment={
                <IconButton
                  size="small"
                  onClick={() => onDeleteKnowledgeBase?.(data.id)}
                >
                  <IcClose />
                </IconButton>
              }
              css={css`
                border: 0;
              `}
            />
          ))}
        </div>
      )}
      <MenuItem
        onClick={ev => onSelect?.(KnowledgeBaseSource.KnowledgeBase, ev)}
      >
        <ListItemIcon>
          <IcKnowledgeBase />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage
            id="knowledge-base.upload-menu.add"
            defaultMessage="Add from Knowledge base"
            description="Add document from knowledge base menu label"
          />
        </ListItemText>
      </MenuItem>
      <MenuItem onClick={ev => onSelect?.(KnowledgeBaseSource.Computer, ev)}>
        <ListItemIcon>
          <IcFile />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage
            id="knowledge-base.upload-menu.upload"
            defaultMessage="Upload from computer"
            description="Upload document from computer menu label"
          />
        </ListItemText>
      </MenuItem>
    </Menu>
  );
};
