import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { ResultOf } from '@graphql-typed-document-node/core';
import { gql } from '../gql';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IsChatOptionInfoDisabled_ChatFragment = gql(`
  fragment IsChatOptionInfoDisabled_ChatFragment on Chat {
    ...on BotChat {
      id
      multipleOption
      reusable
    }

    ...on CarouselChat {
      id
    }
  }
`);

const conversationStates: (UserQueryTypes.ConversationStateEnum | undefined)[] =
  [
    UserQueryTypes.ConversationStateEnum.WAIT_AGENT,
    UserQueryTypes.ConversationStateEnum.INTERRUPTED,
    UserQueryTypes.ConversationStateEnum.END_BY_USER,
    UserQueryTypes.ConversationStateEnum.END_OF_CHATBOT,
    UserQueryTypes.ConversationStateEnum.END_BY_AGENT,
    UserQueryTypes.ConversationStateEnum.END_OF_OBSERVER,
  ];

export interface IsChatOptionInfoDisabledOptions {
  chat: ResultOf<typeof IsChatOptionInfoDisabled_ChatFragment>;
  conversation?: Pick<UserQueryTypes.Conversation, 'state'> | null;
  lastChat?: Pick<UserQueryTypes.Chat, 'id'>;
}

export const isChatOptionInfoDisabled = ({
  chat,
  conversation,
  lastChat,
}: IsChatOptionInfoDisabledOptions): boolean => {
  if (
    chat.__typename === 'EventChat' ||
    chat.__typename === 'CustomUIChat' ||
    chat.__typename === 'FillSlotRichUXChat'
  ) {
    return true;
  }

  // if multiple choice, disable form entirely if it's not the last chat and not reusable
  if (chat.__typename === 'BotChat' && chat.multipleOption) {
    return (
      !(chat.reusable || chat.id === lastChat?.id) ||
      conversationStates.includes(conversation?.state)
    );
  }

  return conversationStates.includes(conversation?.state);
};
