import {
  ChatDivider,
  ChatStack,
  ChatStackItem,
} from '@allganize/alli-chat-base';
import { Types } from '@allganize/alli-sdk-interfaces';
import { Avatar } from '@allganize/ui-avatar';
import { IcOutgoingMail } from '@allganize/ui-icons';
import { css } from '@emotion/react';
import { forwardRef } from 'react';
import { EventChatFragment } from '../graphql/fragments/event-chat-fragment';
import { EventChatMessage } from './event-chat-message';

const iconStyle = css`
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
`;

interface EventChatProps {
  chat: EventChatFragment;
}

export const EventChat = forwardRef<HTMLDivElement, EventChatProps>(
  ({ chat }, ref) => {
    return (
      <ChatStack ref={ref}>
        <ChatStackItem>
          <ChatDivider>
            {chat.eventType ===
              Types.ChatEventEnum.SEND_CONVERSATION_TO_EMAIL && (
              <span css={iconStyle}>
                <IcOutgoingMail />
              </span>
            )}

            {chat.eventType === Types.ChatEventEnum.AGENT_ENTER && (
              <span css={iconStyle}>
                <Avatar
                  size="smallest"
                  src={chat.enteringAgent?.avatar?.url}
                  alt={
                    chat.enteringAgent?.displayName ||
                    chat.enteringAgent?.firstName ||
                    chat.enteringAgent?.lastName ||
                    undefined
                  }
                >
                  {(
                    chat.enteringAgent?.displayName ||
                    chat.enteringAgent?.firstName ||
                    chat.enteringAgent?.lastName
                  )?.charAt(0)}
                </Avatar>
              </span>
            )}

            <EventChatMessage chat={chat} />
          </ChatDivider>
        </ChatStackItem>
      </ChatStack>
    );
  },
);
