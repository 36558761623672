import { createContext } from 'react';

export interface DialogContextValue {
  onClose?: {
    bivarianceHack(
      event: {},
      reason: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick',
    ): void;
  }['bivarianceHack'];
}

export const DialogContext = createContext<DialogContextValue>({});
