import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { noop } from 'lodash-es';
import { createContext } from 'react';
import { DownloadFileFormValues } from '../download-file-dialog/download-file-form-values';

interface DownloadFile {
  status: UserQueryTypes.PrepareFileStatusEnum | null;
  download: (values: DownloadFileFormValues) => void;
  downloadFile: () => void;
}

export const DownloadFileContext = createContext<DownloadFile>({
  status: null,
  download: noop,
  downloadFile: noop,
});
