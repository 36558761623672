import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { EditorState } from '@allganize/draft-input';
import {
  AlliGraphQLError,
  useFormatErrorMessage,
} from '@allganize/utils-graphql';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo } from 'react';
import { FieldPath, useForm } from 'react-hook-form';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { z } from 'zod';

export interface UserFeedbackImproverFormValues {
  suggestedAnswer: EditorState;
  feedbackSelection: UserQueryTypes.UserAnswerFeedbackSelection[];
}

const defaultUserFeedbackImproverFormValues: UserFeedbackImproverFormValues = {
  suggestedAnswer: EditorState.createEmpty(),
  feedbackSelection: [],
};

type UserFeedbackImproverFormErrorField =
  | FieldPath<UserFeedbackImproverFormValues>
  | 'root';
const userFeedbackImproverFormErrorFields: UserFeedbackImproverFormErrorField[] =
  ['suggestedAnswer', 'feedbackSelection', 'root'];

const isUserFeedbackImproverFormErrorField = (
  field: any,
): field is UserFeedbackImproverFormErrorField =>
  userFeedbackImproverFormErrorFields.includes(field);

export const USER_ANSWER_FEEDBACK_SELECTIONS = [
  UserQueryTypes.UserAnswerFeedbackSelection.NOT_RELEVANT,
  UserQueryTypes.UserAnswerFeedbackSelection.INACCURATE,
  UserQueryTypes.UserAnswerFeedbackSelection.NOT_BEST_ANSWER,
] as const satisfies readonly UserQueryTypes.UserAnswerFeedbackSelection[];

const errorMessages = defineMessages({
  required: {
    id: 'user-feedback-improver-form.errors.required',
    defaultMessage: 'Please enter suggestion or provide feedback.',
    description: 'Field required error message',
  },
});

const createValidationSchema = (intl: IntlShape) => {
  return z
    .object({
      suggestedAnswer: z.instanceof(EditorState),
      feedbackSelection: z.array(
        z.nativeEnum(UserQueryTypes.UserAnswerFeedbackSelection),
      ),
    })
    .refine(
      data =>
        !!data.suggestedAnswer.getCurrentContent().getPlainText('\n').trim() ||
        data?.feedbackSelection?.length > 0,
      {
        path: ['feedbackSelection'],
        message: intl.formatMessage(errorMessages.required),
      },
    ) satisfies z.ZodSchema<UserFeedbackImproverFormValues>;
};

export interface UseUserFeedbackImproverFormOptions {
  defaultValues?: UserFeedbackImproverFormValues;
  onSubmit(values: UserFeedbackImproverFormValues): Promise<void>;
}

export const useUserFeedbackImproverForm = ({
  defaultValues = defaultUserFeedbackImproverFormValues,
  onSubmit,
}: UseUserFeedbackImproverFormOptions) => {
  const intl = useIntl();
  const formatErrorMessage = useFormatErrorMessage();
  const validationSchema = useMemo(
    () => zodResolver(createValidationSchema(intl)),
    [intl],
  );
  const form = useForm<UserFeedbackImproverFormValues>({
    mode: 'all',
    defaultValues,
    resolver: validationSchema,
  });

  const { reset, setError } = form;

  const submit = async (values: UserFeedbackImproverFormValues) => {
    try {
      await onSubmit(values);
      reset();
    } catch (err) {
      if (err instanceof AlliGraphQLError) {
        const errorGroups = err.groupErrorsByField({
          intl,
          isErrorField: isUserFeedbackImproverFormErrorField,
        });

        // Set the errors in the form using setError
        Object.entries(errorGroups).forEach(([field, errors]) => {
          if (!errors) {
            return;
          }

          setError(field as UserFeedbackImproverFormErrorField, {
            message: errors[0]?.message,
          });
        });

        return;
      }

      if (err instanceof Error) {
        setError('root', {
          message: formatErrorMessage(err),
        });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }

      setError('root', {
        message: formatErrorMessage(),
      });
    }
  };

  // Reset the form when the default values change
  useEffect(() => {
    reset(defaultValues, {
      keepIsSubmitSuccessful: true,
      keepIsSubmitted: true,
      keepSubmitCount: true,
    });
  }, [defaultValues, reset]);

  return { form, submit };
};
