import { ConversationContext } from '@allganize/alli-sdk/conversation-detail/conversation-context';
import {
  useChatFormAutocomplete,
  UseChatFormReturn,
} from '@allganize/alli-chat-form';
import { useProject } from '@allganize/alli-sdk/project/use-project';
import { SingleActionPreviewContext } from '@allganize/alli-sdk/single-action-preview/single-action-preview-context';
import { ChatFragment } from '@allganize/alli-sdk/graphql/fragments/chat-fragment';
import { useContext } from 'react';
import { analytics } from '../../analytics';
import { useMentionApp } from '../../hooks/use-mention-app';

interface UseAutocompleteProps {
  chatForm: UseChatFormReturn;
  lastChat: ChatFragment | null;
}

export const useAutocomplete = ({
  chatForm,
  lastChat,
}: UseAutocompleteProps) => {
  const {
    data: { conversation },
  } = useContext(ConversationContext);
  const { project } = useProject();
  const { openSingleActionPreview } = useContext(SingleActionPreviewContext);
  const assistantEnabled =
    !project?.useCustomAssistantEntryLlmApp &&
    project?.assistantEntryLlmApp?.campaign?.id ===
      conversation?.llmApp?.campaign?.id;

  const {
    form: { watch },
  } = chatForm;
  const message = watch('message');

  const questionAutocomplete = useChatFormAutocomplete({
    message,
    messageFieldEnabled: chatForm.messageFieldEnabled,
    conversationId: conversation?.id,
    formDisabled: chatForm.formDisabled,
    enabled: !!project?.sdkSettings?.useAutoComplete,
    lastChat,
    numAutoComplete: project?.sdkSettings?.numAutoComplete,
    setValue: chatForm.form.setValue,
  });

  const mentionAppAutocomplete = useMentionApp({
    enabled: assistantEnabled,
    message,
    formDisabled: chatForm.formDisabled,
    onSelectOption: app => {
      if (app) {
        openSingleActionPreview(app.singleActionApp);
      }
      chatForm.form.setValue('message', '');
      analytics.track(
        'conversation-detail::chat-input__app-mention-selector__app.click',
        {
          conversationId: conversation?.id,
          appId: conversation?.llmApp?.id,
          type: app ? 'app' : 'app-list',
          clickedAppId: app?.id,
        },
      );
    },
    useAutocompleteProps: {
      onOpen: () => {
        analytics.track(
          'conversation-detail::chat-input__app-mention-selector.open',
          {
            conversationId: conversation?.id,
            appId: conversation?.llmApp?.id,
          },
        );
      },
    },
  });

  const autocomplete = assistantEnabled
    ? mentionAppAutocomplete
    : questionAutocomplete;

  return autocomplete;
};
