/* eslint-disable */
import * as UserQueryTypes from '@allganize/data-access-alli-user-query';

import {
  KnowledgeBaseProcessStateFragment_CSVKnowledgeBase_,
  KnowledgeBaseProcessStateFragment_GoogleDocsKnowledgeBase_,
  KnowledgeBaseProcessStateFragment_HWPKnowledgeBase_,
  KnowledgeBaseProcessStateFragment_ImageKnowledgeBase_,
  KnowledgeBaseProcessStateFragment_MSDocsKnowledgeBase_,
  KnowledgeBaseProcessStateFragment_MSExcelKnowledgeBase_,
  KnowledgeBaseProcessStateFragment_MSPPTKnowledgeBase_,
  KnowledgeBaseProcessStateFragment_OldMSDocsKnowledgeBase_,
  KnowledgeBaseProcessStateFragment_PDFKnowledgeBase_,
  KnowledgeBaseProcessStateFragment_TextKnowledgeBase_,
} from '../fragments/knowledge-base-process-state-fragment';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type KnowledgeBasesPublicSubscriptionVariables = UserQueryTypes.Exact<{
  ids?: UserQueryTypes.InputMaybe<
    Array<UserQueryTypes.InputMaybe<UserQueryTypes.Scalars['ID']['input']>>
  >;
}>;

export type KnowledgeBasesPublicSubscription = {
  __typename: 'UserSubscription';
} & {
  knowledgeBasesPublic: UserQueryTypes.Maybe<
    | ({
        __typename: 'CSVKnowledgeBase';
      } & KnowledgeBaseProcessStateFragment_CSVKnowledgeBase_)
    | ({
        __typename: 'GoogleDocsKnowledgeBase';
      } & KnowledgeBaseProcessStateFragment_GoogleDocsKnowledgeBase_)
    | ({
        __typename: 'HWPKnowledgeBase';
      } & KnowledgeBaseProcessStateFragment_HWPKnowledgeBase_)
    | ({
        __typename: 'ImageKnowledgeBase';
      } & KnowledgeBaseProcessStateFragment_ImageKnowledgeBase_)
    | ({
        __typename: 'MSDocsKnowledgeBase';
      } & KnowledgeBaseProcessStateFragment_MSDocsKnowledgeBase_)
    | ({
        __typename: 'MSExcelKnowledgeBase';
      } & KnowledgeBaseProcessStateFragment_MSExcelKnowledgeBase_)
    | ({
        __typename: 'MSPPTKnowledgeBase';
      } & KnowledgeBaseProcessStateFragment_MSPPTKnowledgeBase_)
    | ({
        __typename: 'OldMSDocsKnowledgeBase';
      } & KnowledgeBaseProcessStateFragment_OldMSDocsKnowledgeBase_)
    | ({
        __typename: 'PDFKnowledgeBase';
      } & KnowledgeBaseProcessStateFragment_PDFKnowledgeBase_)
    | ({
        __typename: 'TextKnowledgeBase';
      } & KnowledgeBaseProcessStateFragment_TextKnowledgeBase_)
  >;
};

export const KnowledgeBasesPublicSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'KnowledgeBasesPublicSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'knowledgeBasesPublic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'KnowledgeBaseProcessStateFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'KnowledgeBaseProcessStateFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KnowledgeBase' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GoogleDocsKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TextKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ImageKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MSDocsKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PDFKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MSExcelKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MSPPTKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OldMSDocsKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CSVKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'HWPKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  KnowledgeBasesPublicSubscription,
  KnowledgeBasesPublicSubscriptionVariables
>;
