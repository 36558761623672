import { useProject } from '@allganize/alli-app-market-project';
import { ConversationContext } from '@allganize/alli-sdk/conversation-detail/conversation-context';
import { ChatListContext } from '@allganize/alli-sdk/chat-list/chat-list-context';
import { useChatForm } from '@allganize/alli-chat-form';
import { useSendChat } from '@allganize/alli-sdk/hooks/use-send-chat';
import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { AlliGraphQLError } from '@allganize/utils-graphql';
import { ContentState } from 'draft-js';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { analytics } from '../../analytics';
import { mallyErrors } from '../../i18n/mally-errors';

export const useChatFooter = () => {
  const intl = useIntl();
  const project = useProject();
  const {
    data: { conversation },
  } = useContext(ConversationContext);
  const { data: chatListData } = useContext(ChatListContext);
  const { sendChat } = useSendChat();
  const lastChat =
    chatListData?.chats?.edges[chatListData.chats.edges.length - 1]?.node ??
    null;
  const hasNonCompletedEdges = !!chatListData?.chats?.edges.some(
    edge =>
      edge?.node?.__typename === 'BotChat' && edge.node.completed === false,
  );
  const chatForm = useChatForm({
    conversationState: conversation?.state,
    lastChat,
    hasNonCompletedEdges,
    onSubmit: async values => {
      analytics.track(
        'conversation-detail::chat-input__send-chat-button.click',
        {
          conversationId: conversation?.id,
          appId: conversation?.llmApp?.id,
        },
      );

      if (!conversation) {
        throw new Error(intl.formatMessage(mallyErrors.UNKNOWN));
      }

      const createdAt = Date.now();
      const message = values.message;
      const result = await sendChat(
        {
          knowledgeBaseIds: values.knowledgeBases?.map(kb => kb.id),
          fallbackAction:
            values.intent === 'default' ? undefined : values.intent,
          conversationId: conversation.id,
          message: values.message || undefined,
          mediaInput: values.file?.type.startsWith('image/')
            ? {
                file: values.file,
                mediaType: UserQueryTypes.MediaTypeEnum.IMAGE,
                metaInfo: JSON.stringify({
                  lastModified: values.file.lastModified,
                  lastModifiedDate: (values.file as any).lastModifiedDate,
                  name: values.file.name,
                  size: values.file.size,
                  type: values.file.type,
                }),
              }
            : undefined,
          fileInput:
            !values.file || values.file?.type.startsWith('image/')
              ? undefined
              : {
                  file: values.file,
                  metaInfo: JSON.stringify({
                    lastModified: values.file.lastModified,
                    lastModifiedDate: (values.file as any).lastModifiedDate,
                    name: values.file.name,
                    size: values.file.size,
                    type: values.file.type,
                  }),
                },
        },
        [
          {
            __typename: 'UserChat',
            id: `optimistic-${createdAt}`,
            message,
            messageContentState: ContentState.createFromText(message, '\n'),
            createdAt,
            nextInputType: [],
            useAutoComplete: false,
            hidden: null,
            knowledgeBases:
              values.knowledgeBases?.map(kb => ({
                __typename: kb.type as any,
                id: kb.id,
                title: kb.fileName,
              })) ?? null,
            media: values.file?.type.startsWith('image/')
              ? {
                  __typename: 'Media',
                  id: `optimistic-${createdAt}-media`,
                  mediaType: 'IMAGE',
                  url: URL.createObjectURL(values.file),
                  metaInfo: JSON.stringify({
                    lastModified: values.file.lastModified,
                    lastModifiedDate: (values.file as any).lastModifiedDate,
                    name: values.file.name,
                    size: values.file.size,
                    type: values.file.type,
                  }),
                  width: null,
                  height: null,
                }
              : null,
            file:
              !values.file || values.file.type.startsWith('image/')
                ? null
                : {
                    __typename: 'File',
                    id: `optimistic-${createdAt}-file`,
                    fileId: `optimistic-${createdAt}-file`,
                    url: URL.createObjectURL(values.file),
                    metaInfo: JSON.stringify({
                      lastModified: values.file.lastModified,
                      lastModifiedDate: (values.file as any).lastModifiedDate,
                      name: values.file.name,
                      size: values.file.size,
                      type: values.file.type,
                    }),
                    createdAt: new Date(createdAt).toISOString(),
                    modifiedAt: new Date(createdAt).toISOString(),
                  },
            formValues: null,
          },
        ],
      );

      if (!result?.data) {
        throw new Error(intl.formatMessage(mallyErrors.UNKNOWN));
      }

      if ('trySendChat' in result.data) {
        if (
          result.data.trySendChat?.errors &&
          result.data.trySendChat.errors.length > 0
        ) {
          throw new AlliGraphQLError(result.data.trySendChat.errors);
        }

        throw new Error(intl.formatMessage(mallyErrors.UNKNOWN));
      }

      if (
        result.data.sendChat?.errors &&
        result.data.sendChat.errors.length > 0
      ) {
        throw new AlliGraphQLError(result.data.sendChat.errors);
      }
    },
  });

  const assistantEnabled =
    !project.useCustomAssistantEntryLlmApp &&
    project.assistantEntryLlmApp?.campaign?.id ===
      conversation?.llmApp?.campaign?.id;

  const selectedKnowledgeBases = chatForm.form.watch('knowledgeBases');
  const assistantPlaceholder = selectedKnowledgeBases?.length
    ? intl.formatMessage({
        id: 'chat-form.assistant-knowledge-base.placeholder',
        defaultMessage: 'How can I assist you with the document?',
        description:
          'assistant chat form placeholder when knowledge base is selected',
      })
    : intl.formatMessage({
        id: 'chat-form.assistant.placeholder',
        defaultMessage: 'What task can I help you with?',
        description: 'assistant chat form placeholder',
      });

  const cautionMessage = project.useCautionMessage
    ? intl.formatMessage({
        id: 'caution-message.text',
        defaultMessage:
          'Please one more check the answer, it may be inaccurate.',
        description: 'caution message to check AI answer',
      })
    : '';

  return {
    chatForm,
    lastChat,
    assistantEnabled,
    assistantPlaceholder,
    cautionMessage,
  };
};
