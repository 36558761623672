/* eslint-disable */
import * as UserQueryTypes from '@allganize/data-access-alli-user-query';

import {
  SingleActionInputKnowledgeBaseFragment_CSVKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_GoogleDocsKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_HWPKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_ImageKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_MSDocsKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_MSExcelKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_MSPPTKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_OldMSDocsKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_PDFKnowledgeBase_,
  SingleActionInputKnowledgeBaseFragment_TextKnowledgeBase_,
} from '../fragments/single-action-input-knowledge-base-fragment';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type KnowledgeBasesPublicSubscriptionVariables = UserQueryTypes.Exact<{
  where?: UserQueryTypes.InputMaybe<UserQueryTypes.ProjectWhereUniqueInput>;
  ids?: UserQueryTypes.InputMaybe<
    Array<UserQueryTypes.InputMaybe<UserQueryTypes.Scalars['ID']['input']>>
  >;
  accessToken: UserQueryTypes.Scalars['String']['input'];
}>;

export type KnowledgeBasesPublicSubscription = {
  __typename: 'UserSubscription';
} & {
  knowledgeBasesPublic: UserQueryTypes.Maybe<
    | ({
        __typename: 'CSVKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_CSVKnowledgeBase_)
    | ({
        __typename: 'GoogleDocsKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_GoogleDocsKnowledgeBase_)
    | ({
        __typename: 'HWPKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_HWPKnowledgeBase_)
    | ({
        __typename: 'ImageKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_ImageKnowledgeBase_)
    | ({
        __typename: 'MSDocsKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_MSDocsKnowledgeBase_)
    | ({
        __typename: 'MSExcelKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_MSExcelKnowledgeBase_)
    | ({
        __typename: 'MSPPTKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_MSPPTKnowledgeBase_)
    | ({
        __typename: 'OldMSDocsKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_OldMSDocsKnowledgeBase_)
    | ({
        __typename: 'PDFKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_PDFKnowledgeBase_)
    | ({
        __typename: 'TextKnowledgeBase';
      } & SingleActionInputKnowledgeBaseFragment_TextKnowledgeBase_)
  >;
};

export const KnowledgeBasesPublicSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'KnowledgeBasesPublicSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ProjectWhereUniqueInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accessToken' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'knowledgeBasesPublic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accessToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accessToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SingleActionInputKnowledgeBaseFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleActionInputKnowledgeBaseFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'KnowledgeBase' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GoogleDocsKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TextKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ImageKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MSDocsKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PDFKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MSExcelKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MSPPTKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OldMSDocsKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CSVKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'HWPKnowledgeBase' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processState' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'processRetryAt' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  KnowledgeBasesPublicSubscription,
  KnowledgeBasesPublicSubscriptionVariables
>;
