import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { alliChatRowItemClasses } from './alli-chat-row-item.classes';
import { AlliChatRowItemProps } from './alli-chat-row-item.types';

const avatarWidth = 28;

export const AlliChatRowItem = forwardRef<HTMLDivElement, AlliChatRowItemProps>(
  (props, ref) => {
    const { avatar, children, classes, className, ...other } = props;

    return (
      <div
        data-testid="alli-chat-row-item"
        css={css`
          display: flex;
          align-items: flex-start;
          padding: 11px 0;
        `}
        {...other}
        ref={ref}
        className={clsx(alliChatRowItemClasses.root, classes?.root, className)}
      >
        <div
          css={css`
            flex-shrink: 0;
            width: ${avatarWidth}px;
          `}
          className={clsx(alliChatRowItemClasses.avatar, classes?.avatar)}
        >
          {avatar}
        </div>

        <div
          css={css`
            flex-grow: 1;
            max-width: calc(100% - ${avatarWidth}px);
          `}
          className={clsx(alliChatRowItemClasses.content, classes?.content)}
        >
          {children}
        </div>
      </div>
    );
  },
);
