import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  DraftBlockRenderConfig,
  DraftBlockRenderMap,
  DraftBlockType,
  DraftDecorator,
  DraftDragType,
  DraftHandleValue,
  DraftInlineStyle,
  DraftStyleMap,
  Editor,
  EditorCommand,
  EditorProps,
  EditorState,
  SelectionState,
} from 'draft-js';
import { Map } from 'immutable';
import { DraftPluginFunctions } from './draft-plugin-functions';

export interface BlockRendererProps<T>
  extends Pick<
    EditorProps,
    'blockStyleFn' | 'customStyleFn' | 'customStyleMap'
  > {
  block?: ContentBlock;
  blockProps?: T;
  contentState?: ContentState;
  decorator?: CompositeDecorator | null;
  direction?: EditorProps['textDirectionality'];
  forceSelection?: boolean;
  offsetKey?: string;
  preventScroll?: unknown;
  selection?: SelectionState;
  tree?: Immutable.List<any>;
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export class DraftPlugin {
  public blockRendererFn(
    block: ContentBlock,
    pluginFunctions: DraftPluginFunctions,
  ): any {
    return null;
  }

  public getDecorators(): DraftDecorator[] | null {
    return null;
  }

  public onChange(editorState: EditorState): EditorState {
    return editorState;
  }

  public getAccessibilityProps(): Pick<
    EditorProps,
    | 'role'
    | 'ariaAutoComplete'
    | 'ariaExpanded'
    | 'ariaOwneeID'
    | 'ariaActiveDescendantID'
  > {
    return {};
  }

  public keyBindingFn(
    e: React.KeyboardEvent,
    pluginFunctions: DraftPluginFunctions,
  ): EditorCommand | null {
    return null;
  }

  public handleKeyCommand(
    command: EditorCommand,
    editorState: EditorState,
    eventTimeStamp: number,
    pluginFunctions: DraftPluginFunctions,
  ): DraftHandleValue {
    return 'not-handled';
  }

  public handleReturn(
    e: React.KeyboardEvent,
    editorState: EditorState,
    pluginFunctions: DraftPluginFunctions,
  ): DraftHandleValue {
    return 'not-handled';
  }

  public handleDrop(
    selection: SelectionState,
    // eslint-disable-next-line @typescript-eslint/no-wrapper-object-types
    dataTransfer: Object,
    isInternal: DraftDragType,
    pluginFunctions: DraftPluginFunctions,
  ): DraftHandleValue {
    return 'not-handled';
  }

  public handleDroppedFiles(
    selection: SelectionState,
    files: Blob[],
    pluginFunctions: DraftPluginFunctions,
  ): DraftHandleValue {
    return 'not-handled';
  }

  public handlePastedFiles(
    files: Blob[],
    pluginFunctions: DraftPluginFunctions,
  ): DraftHandleValue {
    return 'not-handled';
  }

  public handlePastedText(
    text: string,
    html: string | undefined,
    editorState: EditorState,
    pluginFunctions: DraftPluginFunctions,
  ): DraftHandleValue {
    return 'not-handled';
  }

  public formatPastedText({ text, html }: { text: string; html?: string }): {
    text: string;
    html: string | undefined;
  } {
    return { text, html };
  }

  public handleBeforeInput(
    chars: string,
    editorState: EditorState,
    eventTimeStamp: number,
    pluginFunctions: DraftPluginFunctions,
  ): DraftHandleValue {
    return 'not-handled';
  }

  public onBlur(
    ev: React.SyntheticEvent,
    pluginFunctions: DraftPluginFunctions,
  ) {
    // noop
  }

  public onFocus(
    ev: React.SyntheticEvent,
    pluginFunctions: DraftPluginFunctions,
  ) {
    // noop
  }

  public onCopy(
    editor: Editor,
    e: React.ClipboardEvent,
    pluginFunctions: DraftPluginFunctions,
  ) {
    // noop
  }

  public onCut(
    editor: Editor,
    e: React.ClipboardEvent,
    pluginFunctions: DraftPluginFunctions,
  ) {
    // noop
  }

  public customStyleFn(
    style: DraftInlineStyle,
    block: ContentBlock,
    pluginFunctions: DraftPluginFunctions,
  ): React.CSSProperties {
    return {};
  }

  public getCustomStyleMap(
    pluginFunctions: DraftPluginFunctions,
  ): DraftStyleMap {
    return {};
  }

  public getBlockRenderMap(
    pluginFunctions: DraftPluginFunctions,
  ): DraftBlockRenderMap {
    return Map<DraftBlockType, DraftBlockRenderConfig>();
  }

  public blockStyleFn(
    block: ContentBlock,
    pluginFunctions: DraftPluginFunctions,
  ): string | null {
    return null;
  }
}
/* eslint-enable @typescript-eslint/no-unused-vars */
