import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import {
  IcAppMarket,
  IcDocumentSearch,
  IcFace,
  IcGenerate,
} from '@allganize/ui-icons';
import { defineMessage } from 'react-intl';
import { SelectOption } from './assistant-intent-selector-types';

export const assistantIntentSelectorOptions: Record<string, SelectOption> = {
  default: {
    label: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.default',
      defaultMessage: 'Ai Mode',
      description: 'Intent select default label text',
    }),
    description: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.default.description',
      defaultMessage: 'Automatically choose the best response for you',
      description: 'Intent select default description text',
    }),
    value: 'default',
    icon: <IcGenerate />,
  },
  SEARCH: {
    label: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.SEARCH',
      defaultMessage: 'Search',
      description: 'Intent select search label text',
    }),
    description: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.SEARCH.description',
      defaultMessage: 'Search from uploaded documents or websites',
      description: 'Intent select search description text',
    }),
    value: UserQueryTypes.IntentClassificationIntents.SEARCH,
    icon: <IcDocumentSearch />,
  },
  RECOMMEND_MORE_APPS: {
    label: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.RECOMMEND_MORE_APPS',
      defaultMessage: 'App Recommendation',
      description: 'Intent select app recommend label text',
    }),
    description: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.RECOMMEND_MORE_APPS.description',
      defaultMessage: 'Recommend the best app for your tasks',
      description: 'Intent select app recommend description text',
    }),
    value: UserQueryTypes.IntentClassificationIntents.RECOMMEND_MORE_APPS,
    icon: <IcAppMarket />,
  },
  SMALLTALK: {
    label: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.SMALLTALK',
      defaultMessage: 'Talk',
      description: 'Intent select smalltalk label text',
    }),
    description: defineMessage({
      id: 'app-market.conversation.assistant-intent-select.intent.SMALLTALK.description',
      defaultMessage: 'Talk freely with the AI assistant',
      description: 'Intent select smalltalk description text',
    }),
    value: UserQueryTypes.IntentClassificationIntents.SMALLTALK,
    icon: <IcFace />,
  },
} satisfies Partial<
  Record<UserQueryTypes.IntentClassificationIntents | 'default', SelectOption>
>;
