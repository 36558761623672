import { ChatForm } from '@allganize/alli-chat-form';
import { ConversationContext } from '@allganize/alli-sdk/conversation-detail/conversation-context';
import { css, useTheme } from '@emotion/react';
import { useContext } from 'react';
import { AssistantIntentSelector } from '../assistant-intent-selector';
import { AssistantDocumentUploadButton } from '../assistant-document-upload-button/assistant-document-upload-button';
import { contentMaxWidth } from './conversation-detail-layout';
import { useAutocomplete } from './use-autocomplete';
import { useChatFooter } from './use-chat-footer';

export const Footer = () => {
  const theme = useTheme();
  const {
    data: { conversation },
  } = useContext(ConversationContext);

  const {
    chatForm,
    lastChat,
    assistantEnabled,
    assistantPlaceholder,
    cautionMessage,
  } = useChatFooter();
  const autocomplete = useAutocomplete({ chatForm, lastChat });

  return (
    <footer
      css={css`
        position: sticky;
        bottom: 0;
        max-width: ${contentMaxWidth}px;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 12px;
        background-color: ${theme.palette.unstable_background.white};
      `}
    >
      <ChatForm
        open={true}
        chatForm={chatForm}
        autocomplete={autocomplete}
        MessageFieldInputProps={{
          startAdornment: assistantEnabled && (
            <AssistantIntentSelector
              control={chatForm.form.control}
              disabled={chatForm.formDisabled}
              conversationId={conversation?.id}
              llmAppId={conversation?.llmApp?.id}
              menuProps={{
                anchorOrigin: {
                  vertical: -10,
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }}
            />
          ),
          endAdornment: assistantEnabled && (
            <AssistantDocumentUploadButton
              control={chatForm.form.control}
              disabled={chatForm.formDisabled}
              conversationId={conversation?.id}
              llmAppId={conversation?.llmApp?.id}
            />
          ),
          ...(assistantEnabled ? { placeholder: assistantPlaceholder } : {}),
        }}
      />
      {cautionMessage && (
        <div
          css={css`
            text-align: center;
            font-size: 10px;
            line-height: 10px;
            font-weight: 500;
            color: ${theme.palette.foreground.placeholder};
          `}
        >
          {cautionMessage}
        </div>
      )}
    </footer>
  );
};
