import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { ResultOf } from '@graphql-typed-document-node/core';
import { gql } from '../gql';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IsContactAgentDisabled_ChatFragment = gql(`
  fragment IsContactAgentDisabled_ChatFragment on Chat {
    ...on AgentChat {
      id
      nextInputType
    }

    ...on BotChat {
      id
      nextInputType
    }

    ...on CarouselChat {
      id
      nextInputType
    }

    ...on ContactAgentByEmailChat {
      id
      nextInputType
    }

    ...on SendFormChat {
      id
      nextInputType
    }

    ...on UserChat {
      id
      nextInputType
    }
  }
`);

const conversationStates: Array<
  UserQueryTypes.ConversationStateEnum | undefined
> = [
  UserQueryTypes.ConversationStateEnum.END_BY_USER,
  UserQueryTypes.ConversationStateEnum.END_OF_CHATBOT,
  UserQueryTypes.ConversationStateEnum.END_BY_AGENT,
  UserQueryTypes.ConversationStateEnum.END_OF_OBSERVER,
  UserQueryTypes.ConversationStateEnum.INTERRUPTED,
];

export interface IsContactAgentDisabledOptions {
  chat: ResultOf<typeof IsContactAgentDisabled_ChatFragment>;
  conversation?: Pick<UserQueryTypes.Conversation, 'state'> | null;
  lastChat?: Pick<UserQueryTypes.Chat, 'id'>;
}

export const isContactAgentDisabled = ({
  chat,
  conversation,
  lastChat,
}: IsContactAgentDisabledOptions): boolean => {
  if (
    chat.__typename === 'EventChat' ||
    chat.__typename === 'CustomUIChat' ||
    chat.__typename === 'FillSlotRichUXChat'
  ) {
    return true;
  }

  if (
    !chat.nextInputType.includes(
      UserQueryTypes.ChatInputType.CONTACT_AGENT_BY_EMAIL_RICH_UX,
    )
  ) {
    return true;
  }

  if (chat.id !== lastChat?.id) {
    return true;
  }

  return conversationStates.includes(conversation?.state);
};
