import {
  DocumentViewerPageInfo,
  DocumentViewerPageThumbnailInfo,
  DocumentViewerRef,
  DraftPageInfo,
  HtmlPageInfo,
  ImagePageInfo,
  PdfPageInfo,
} from '@allganize/alli-document';
import { Types } from '@allganize/alli-sdk-interfaces';
import { convertFromRaw, EditorState } from '@allganize/draft-input';
import { DraftDocumentEntity } from '@allganize/draft-document-plugin';
import { useEventCallback } from '@allganize/hooks';
import { useApolloClient } from '@apollo/client/react';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { pdfjs } from 'react-pdf';
import { useLocation } from 'react-router-dom';
import { useAlliClient } from '../alli-client/use-alli-client';
import { analytics } from '../analytics';
import { config } from '../config';
import { ChatFragment } from '../graphql/fragments/chat-fragment';
import {
  KnowledgeBasePreviewQuery,
  KnowledgeBasePreviewQueryDocument,
  KnowledgeBasePreviewQueryVariables,
} from '../graphql/queries/knowledge-base-preview-query';
import { mallyErrors } from '../i18n/mally-errors';
import { useProject } from '../project/use-project';
import {
  DocumentPreviewState,
  isDocumentPreviewStateEqual,
} from './document-preview-state';
import { getDocumentPreviewThumbnailUrl } from './get-document-preview-thumbnail-url';
import { ClosePreviewReason } from '../preview/preview-state';
import { PreviewContextProps } from '../preview/preview-context';

pdfjs.GlobalWorkerOptions.workerSrc = config.pdfjs.workerSrc;

export const useDocumentPreview = ({
  previewSidebarAnchor,
  shouldOpenPreviewInDialog,
  setPreviewType,
}: PreviewContextProps) => {
  const client = useAlliClient();
  const location = useLocation();
  const apolloClient = useApolloClient();
  const intl = useIntl();
  const { project } = useProject();
  const documentViewerRef = useRef<DocumentViewerRef>(null);

  const [documentPreviewState, setDocumentPreviewState] =
    useState<DocumentPreviewState | null>(null);
  const documentPreviewOpen = documentPreviewState !== null;
  const region = client.authClient.getRegionFromEndpoint();

  const getThumbnails = (
    where: Types.KnowledgeBaseWhereUniqueInput,
    pageCount: number,
  ): DocumentViewerPageThumbnailInfo[] => {
    if (!project) {
      return Array.from({ length: pageCount }).map(() => ({}));
    }

    return Array.from({ length: pageCount }).map((_, index) => ({
      src: getDocumentPreviewThumbnailUrl({
        projectId: project.id,
        knowledgeBaseId: where.id,
        pageNo: index + 1,
        width: 180,
        region,
      }),
    }));
  };

  const getAllPages = (
    variables: Omit<KnowledgeBasePreviewQueryVariables, 'pageNo'>,
    pageCount: number,
    initialPage: number | null | undefined,
  ): (DocumentViewerPageInfo | (() => Promise<DocumentViewerPageInfo>))[] => {
    return Array.from({ length: pageCount }).map((_, index) => {
      return async () => {
        const pageNo = index + 1;
        const highlights = {
          ...variables.highlights,
          answerHighlightId:
            pageNo === initialPage
              ? variables.highlights?.answerHighlightId
              : undefined,
        };
        const result = await apolloClient.query<
          KnowledgeBasePreviewQuery,
          KnowledgeBasePreviewQueryVariables
        >({
          query: KnowledgeBasePreviewQueryDocument,
          variables: {
            ...variables,
            highlights,
            pageNo: index + 1,
          },
        });

        const kbp = result.data.knowledgeBasePreview;
        const previewCapacity = kbp?.knowledgeBase?.previewCapacity;

        const imagePage: ImagePageInfo = {
          type: 'image',
          key: `${variables.where.id}-${index}`,
          url: getDocumentPreviewThumbnailUrl({
            projectId: project?.id ?? '',
            knowledgeBaseId: variables.where.id,
            pageNo: index + 1,
            region,
          }),
          alt: kbp?.knowledgeBase?.title,
          answerHighlights: kbp?.answerHighlights,
        };

        if (project && previewCapacity?.image) {
          return imagePage;
        }

        if (previewCapacity?.draftjs && kbp?.draftjs) {
          const draftPage: DraftPageInfo = {
            type: 'draft',
            key: kbp.draftjs.id,
            editorState: EditorState.createWithContent(
              convertFromRaw(kbp.draftjs.answer),
            ),
          };

          return draftPage;
        }

        if (previewCapacity?.html && kbp?.html) {
          const htmlPage: HtmlPageInfo = {
            type: 'html',
            key: `${variables.where.id}-${index}`,
            html: kbp.html,
            fallback: project ? imagePage : undefined,
          };

          return htmlPage;
        }

        if (previewCapacity?.pdf && kbp?.pdf) {
          const pdfPage: PdfPageInfo = {
            type: 'pdf',
            key: `${variables.where.id}-${index}`,
            url: kbp.pdf,
            highlights: (
              kbp.highlights?.pageIndexes?.find(pi => pi.page === kbp.pageNo)
                ?.indexes ??
              kbp.highlights?.indexes ??
              kbp.highlightIndexes
            )?.reduce<number[]>((acc, curr) => {
              if (curr === null) {
                return acc;
              }

              return [...acc, curr];
            }, []),
            options: {
              cMapUrl: config.pdfjs.cMapUrl,
              cMapPacked: true,
              standardFontDataUrl: config.pdfjs.standardFontDataUrl,
            },
            fallback: project ? imagePage : undefined,
            answerHighlights: kbp.answerHighlights,
          };

          return pdfPage;
        }

        if (!project) {
          return Promise.reject(intl.formatMessage(mallyErrors.UNKNOWN));
        }

        return imagePage;
      };
    });
  };

  const openDocumentPreview = useEventCallback(
    async ({
      chat,
      entity,
      trigger = 'click',
    }: {
      chat: ChatFragment;
      entity: DraftDocumentEntity;
      trigger: DocumentPreviewState['trigger'];
    }) => {
      if (isDocumentPreviewStateEqual(documentPreviewState, { chat, entity })) {
        return;
      }

      const { knowledgeBasePreview } = entity.data;

      if (knowledgeBasePreview.draftjs?.answer) {
        const initialPage = (knowledgeBasePreview.pageNo ?? 1) - 1;
        const title = [entity.text, knowledgeBasePreview.knowledgeBase?.title]
          .filter(Boolean)
          .join(' ');
        const originalWebUri =
          knowledgeBasePreview.knowledgeBase?.originalWebUri;
        documentViewerRef.current?.resetScale();
        setPreviewType('document');
        setDocumentPreviewState({
          chat,
          entity,
          initialPage,
          pages: [
            {
              type: 'draft',
              key: knowledgeBasePreview.draftjs.id ?? entity.key,
              editorState: EditorState.createWithContent(
                convertFromRaw(knowledgeBasePreview.draftjs.answer),
              ),
            },
          ],
          thumbnails: [],
          title,
          originalWebUri,
          trigger,
        });
        return;
      }

      if (
        knowledgeBasePreview.knowledgeBase?.id === null ||
        typeof knowledgeBasePreview.knowledgeBase?.id === 'undefined'
      ) {
        return;
      }

      const highlights:
        | Types.InputMaybe<Types.KnowledgeBasePreviewHighlightsInput>
        | undefined = knowledgeBasePreview.highlights
        ? {
            ...knowledgeBasePreview.highlights,
            pageIndexes: knowledgeBasePreview.highlights.pageIndexes
              ? knowledgeBasePreview.highlights.pageIndexes.map<
                  Types.InputMaybe<Types.KnowledgeBasePreviewPageHighlightInput>
                >(pi => {
                  if (pi?.page === null || typeof pi?.page === 'undefined') {
                    return null;
                  }

                  if (!pi.indexes) {
                    return null;
                  }

                  return {
                    ...pi,
                    page: pi.page,
                    indexes: pi.indexes.reduce<number[]>((acc, curr) => {
                      if (curr === null) {
                        return acc;
                      }

                      return [...acc, curr];
                    }, []),
                  };
                })
              : knowledgeBasePreview.highlights.pageIndexes,
            answerHighlightId: knowledgeBasePreview.answerHighlightId,
          }
        : knowledgeBasePreview.answerHighlightId
        ? { answerHighlightId: knowledgeBasePreview.answerHighlightId }
        : knowledgeBasePreview.highlights;

      if (knowledgeBasePreview.totalPageCount) {
        const initialPage = (knowledgeBasePreview.pageNo ?? 1) - 1;
        const title = [entity.text, knowledgeBasePreview.knowledgeBase.title]
          .filter(Boolean)
          .join(' ');
        const originalWebUri =
          knowledgeBasePreview.knowledgeBase.originalWebUri;
        const pages = getAllPages(
          {
            where: { id: knowledgeBasePreview.knowledgeBase.id },
            highlights,
            highlightIndexes: knowledgeBasePreview.highlightIndexes,
            exactAnswer: knowledgeBasePreview.exactAnswer,
          },
          knowledgeBasePreview.totalPageCount,
          knowledgeBasePreview.pageNo,
        );
        const thumbnails = getThumbnails(
          { id: knowledgeBasePreview.knowledgeBase.id },
          knowledgeBasePreview.totalPageCount,
        );

        documentViewerRef.current?.resetScale();
        setPreviewType('document');
        setDocumentPreviewState({
          chat,
          entity,
          initialPage,
          pages,
          thumbnails,
          title,
          originalWebUri,
          trigger,
        });
        return;
      }

      const result = await apolloClient.query<
        KnowledgeBasePreviewQuery,
        KnowledgeBasePreviewQueryVariables
      >({
        query: KnowledgeBasePreviewQueryDocument,
        variables: {
          where: { id: knowledgeBasePreview.knowledgeBase.id },
          highlights,
          highlightIndexes: knowledgeBasePreview.highlightIndexes,
          exactAnswer: knowledgeBasePreview.exactAnswer,
          pageNo: knowledgeBasePreview.pageNo ?? 1,
        },
      });

      const kbp = result.data.knowledgeBasePreview;

      if (kbp?.totalPageCount) {
        const initialPage = (knowledgeBasePreview.pageNo ?? 1) - 1;
        const title = [entity.text, knowledgeBasePreview.knowledgeBase.title]
          .filter(Boolean)
          .join(' ');
        const originalWebUri = kbp.knowledgeBase?.originalWebUri;
        const pages = getAllPages(
          {
            where: { id: knowledgeBasePreview.knowledgeBase.id },
            highlights,
            highlightIndexes: knowledgeBasePreview.highlightIndexes,
            exactAnswer: knowledgeBasePreview.exactAnswer,
          },
          kbp.totalPageCount,
          kbp.pageNo,
        );
        const thumbnails = getThumbnails(
          { id: knowledgeBasePreview.knowledgeBase.id },
          kbp.totalPageCount,
        );

        documentViewerRef.current?.resetScale();
        setPreviewType('document');
        setDocumentPreviewState({
          chat,
          entity,
          initialPage,
          pages,
          thumbnails,
          title,
          originalWebUri,
          trigger,
        });
        return;
      }
    },
  );

  const closeDocumentPreview = useEventCallback(
    (reason: ClosePreviewReason) => {
      if (documentPreviewState) {
        analytics?.track('close_document_preview', {
          mode: shouldOpenPreviewInDialog ? 'dialog' : 'sidebar',
          previewSidebarAnchor,
          chatId: documentPreviewState?.chat.id,
          entityText: documentPreviewState?.entity.text,
          url: documentPreviewState?.entity.data.url ?? undefined,
          reason,
        });
      }

      documentViewerRef.current?.resetScale();
      setPreviewType(null);
      setDocumentPreviewState(null);
    },
  );

  useEffect(() => {
    closeDocumentPreview('navigation');
  }, [location.pathname, closeDocumentPreview]);

  return {
    documentViewerRef,
    documentPreviewOpen,
    documentPreviewState,
    openDocumentPreview,
    closeDocumentPreview,
  };
};
