import {
  AutocompleteOption,
  ChatFormAutocomplete,
  ChatFormRoot,
} from '@allganize/alli-sdk-ui';
import { useEventCallback } from '@allganize/hooks';
import { UseAutocompleteReturnValue } from '@mui/material';
import { format, isValid as isValidDate } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { UseChatFormReturn } from '../hooks/use-chat-form';
import { dateFormat } from '../utils/chat-form';
import { ChatFormDatePickerField } from './chat-form-date-picker';
import {
  ChatFormFileDropzone,
  useChatFormFileDropzone,
} from './chat-form-file-dropzone';
import {
  ChatFormMessageField,
  ChatFormMessageFieldProps,
} from './chat-form-message-field';

interface ChatFormProps {
  open: boolean;
  chatForm: UseChatFormReturn;
  autocomplete: UseAutocompleteReturnValue<
    AutocompleteOption,
    false,
    true,
    true
  > | null;
  disableAutoFocus?: boolean;
  MessageFieldInputProps?: ChatFormMessageFieldProps['InputProps'];
}

export const ChatForm = ({
  open,
  chatForm,
  autocomplete,
  disableAutoFocus,
  MessageFieldInputProps,
}: ChatFormProps) => {
  const intl = useIntl();
  const {
    dateFieldEnabled,
    dropzoneOptions,
    fileFieldEnabled,
    form: {
      watch,
      resetField,
      clearErrors,
      setFocus,
      handleSubmit,
      control,
      formState,
    },
    formDisabled,
    formRef,
    messageFieldEnabled,
    placeholder,
    submit,
    waitUserAnswer,
    isGenerating,
  } = chatForm;
  const { dropzoneRef, openFilePicker } = useChatFormFileDropzone();

  const [message, file, date] = watch(['message', 'file', 'date']);

  const messageInputValue = useMemo(() => {
    if (fileFieldEnabled && file) return file.name;
    if (dateFieldEnabled && date && isValidDate(date))
      return format(date, dateFormat);
    if (messageFieldEnabled) return message;
    return '';
  }, [
    messageFieldEnabled,
    fileFieldEnabled,
    dateFieldEnabled,
    message,
    file,
    date,
  ]);

  const messageInputPlaceholder = useMemo(() => {
    if (placeholder) return placeholder;
    if (fileFieldEnabled && !messageFieldEnabled)
      return intl.formatMessage(
        {
          id: 'form.file.placeholder',
          defaultMessage: 'Click or drag & drop{br}a file to upload',
          description: 'File input placeholder',
        },
        { br: ' ' },
      );
    return intl.formatMessage({
      id: 'chat-form.message.placeholder',
      defaultMessage: 'Send a message',
      description: 'Chat form message input placeholder',
    });
  }, [intl, placeholder, messageFieldEnabled, fileFieldEnabled]);

  const autoFocus =
    open && waitUserAnswer && !formDisabled && !disableAutoFocus;
  const popupOpen =
    autocomplete?.popupOpen && autocomplete.groupedOptions.length > 0;
  const dateFieldOnly =
    !messageFieldEnabled && !fileFieldEnabled && dateFieldEnabled;

  const handleClearButtonClick = useEventCallback(() => {
    if (fileFieldEnabled) resetField('file');
    if (dateFieldEnabled) resetField('date');
  });

  useEffect(() => {
    if (messageFieldEnabled && !file && !date && autoFocus) {
      setFocus('message');
    }
  }, [autoFocus, messageFieldEnabled, setFocus, file, date]);

  if (dateFieldOnly) {
    return (
      <ChatFormRoot onSubmit={handleSubmit(submit)} ref={formRef}>
        <ChatFormDatePickerField
          control={control}
          formState={formState}
          disabled={formDisabled}
          onClearError={clearErrors}
        />
      </ChatFormRoot>
    );
  }

  return (
    <ChatFormRoot onSubmit={handleSubmit(submit)} ref={formRef}>
      <ChatFormFileDropzone
        {...dropzoneOptions}
        control={control}
        dropzoneRef={dropzoneRef}
        disabled={formDisabled || !fileFieldEnabled}
        noClick={messageFieldEnabled && !file}
      >
        <ChatFormMessageField
          control={control}
          formState={formState}
          value={messageInputValue}
          placeholder={messageInputPlaceholder}
          disabled={formDisabled}
          isSubmitting={isGenerating}
          autoFocus={autoFocus}
          multiline={messageFieldEnabled && !file && !date}
          autocomplete={autocomplete}
          messageFieldEnabled={messageFieldEnabled}
          fileFieldEnabled={fileFieldEnabled}
          dateFieldEnabled={dateFieldEnabled}
          onClear={
            (file || date) && !formDisabled ? handleClearButtonClick : undefined
          }
          onFileUpload={openFilePicker}
          onClearError={clearErrors}
          InputProps={MessageFieldInputProps}
        />
      </ChatFormFileDropzone>
      {popupOpen && <ChatFormAutocomplete autocomplete={autocomplete} />}
    </ChatFormRoot>
  );
};
