import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { Option } from '../contexts/app-context';

export const getIsDocumentInput = (
  inputType: UserQueryTypes.SingleActionInputType,
  knowledgeBases?: Option[],
) => {
  return {
    isDocumentInput:
      inputType === UserQueryTypes.SingleActionInputType.DOCUMENT_BASIC ||
      inputType === UserQueryTypes.SingleActionInputType.DOCUMENT_ADVANCED ||
      inputType === UserQueryTypes.SingleActionInputType.IMAGE_BASIC ||
      inputType === UserQueryTypes.SingleActionInputType.IMAGE_ADVANCED,
    selectMode: !!knowledgeBases && knowledgeBases.length > 0,
  };
};
