/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation UserSendFeedbackMutation(\n    $chatId: ID!\n    $conversationId: ID!\n    $rating: Boolean\n    $mrcInfoId: String\n  ) {\n    userSendFeedback(\n      chatId: $chatId\n      conversationId: $conversationId\n      rating: $rating\n      mrcInfoId: $mrcInfoId\n    ) {\n      chat {\n        ...on BotChat {\n          id\n          rating\n        }\n\n        ...on CarouselChat {\n          id\n\n          chatMrcInfos {\n            id\n            rating\n          }\n        }\n      }\n\n      errors {\n        key\n        message\n        field\n        info\n      }\n    }\n  }\n": types.UserSendFeedbackMutationDocument,
    "\n  mutation UserSendFeedbackImproverMutation(\n    $chatId: ID!\n    $conversationId: ID!\n    $feedbackSelection: [UserAnswerFeedbackSelection]\n    $suggestedAnswer: String\n  ) {\n    userSendFeedbackImprover(\n      chatId: $chatId\n      conversationId: $conversationId\n      feedbackSelection: $feedbackSelection\n      suggestedAnswer: $suggestedAnswer\n    ) {\n      ok\n\n      errors {\n        key\n        message\n        field\n        info\n      }\n    }\n  }\n": types.UserSendFeedbackImproverMutationDocument,
    "\n  fragment UserFeedback_BotChatFragment on BotChat {\n    id\n    mrcAnswerType\n    ratable\n    rating\n  }\n": types.UserFeedback_BotChatFragmentFragmentDoc,
    "\n  fragment UserFeedback_ChatMRCInfoFragment on ChatMRCInfo {\n    id\n    mrcAnswerType\n    ratable\n    rating\n  }\n": types.UserFeedback_ChatMRCInfoFragmentFragmentDoc,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UserSendFeedbackMutation(\n    $chatId: ID!\n    $conversationId: ID!\n    $rating: Boolean\n    $mrcInfoId: String\n  ) {\n    userSendFeedback(\n      chatId: $chatId\n      conversationId: $conversationId\n      rating: $rating\n      mrcInfoId: $mrcInfoId\n    ) {\n      chat {\n        ...on BotChat {\n          id\n          rating\n        }\n\n        ...on CarouselChat {\n          id\n\n          chatMrcInfos {\n            id\n            rating\n          }\n        }\n      }\n\n      errors {\n        key\n        message\n        field\n        info\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UserSendFeedbackMutation(\n    $chatId: ID!\n    $conversationId: ID!\n    $rating: Boolean\n    $mrcInfoId: String\n  ) {\n    userSendFeedback(\n      chatId: $chatId\n      conversationId: $conversationId\n      rating: $rating\n      mrcInfoId: $mrcInfoId\n    ) {\n      chat {\n        ...on BotChat {\n          id\n          rating\n        }\n\n        ...on CarouselChat {\n          id\n\n          chatMrcInfos {\n            id\n            rating\n          }\n        }\n      }\n\n      errors {\n        key\n        message\n        field\n        info\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UserSendFeedbackImproverMutation(\n    $chatId: ID!\n    $conversationId: ID!\n    $feedbackSelection: [UserAnswerFeedbackSelection]\n    $suggestedAnswer: String\n  ) {\n    userSendFeedbackImprover(\n      chatId: $chatId\n      conversationId: $conversationId\n      feedbackSelection: $feedbackSelection\n      suggestedAnswer: $suggestedAnswer\n    ) {\n      ok\n\n      errors {\n        key\n        message\n        field\n        info\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UserSendFeedbackImproverMutation(\n    $chatId: ID!\n    $conversationId: ID!\n    $feedbackSelection: [UserAnswerFeedbackSelection]\n    $suggestedAnswer: String\n  ) {\n    userSendFeedbackImprover(\n      chatId: $chatId\n      conversationId: $conversationId\n      feedbackSelection: $feedbackSelection\n      suggestedAnswer: $suggestedAnswer\n    ) {\n      ok\n\n      errors {\n        key\n        message\n        field\n        info\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UserFeedback_BotChatFragment on BotChat {\n    id\n    mrcAnswerType\n    ratable\n    rating\n  }\n"): (typeof documents)["\n  fragment UserFeedback_BotChatFragment on BotChat {\n    id\n    mrcAnswerType\n    ratable\n    rating\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UserFeedback_ChatMRCInfoFragment on ChatMRCInfo {\n    id\n    mrcAnswerType\n    ratable\n    rating\n  }\n"): (typeof documents)["\n  fragment UserFeedback_ChatMRCInfoFragment on ChatMRCInfo {\n    id\n    mrcAnswerType\n    ratable\n    rating\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;