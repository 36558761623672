import { UseRichTextOptions, useRichText } from '@allganize/alli-chat-content';
import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { ContentState } from '@allganize/draft-input';
import {
  DraftDocumentEntity,
  DraftDocumentPlugin,
} from '@allganize/draft-document-plugin';
import { ResultOf } from '@graphql-typed-document-node/core';
import { omit } from 'lodash-es';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { gql } from '../gql';

export const ChatCitationReferenceFragment = gql(`
  fragment ChatCitationReferenceFragment on CitationReference {
    id
    url
    text

    knowledgeBasePreview {
      knowledgeBase {
        ... on GoogleDocsKnowledgeBase {
          id
          title
        }

        ... on TextKnowledgeBase {
          id
          title
        }

        ... on ImageKnowledgeBase {
          id
          title
        }

        ... on MSDocsKnowledgeBase {
          id
          title
        }

        ... on PDFKnowledgeBase {
          id
          title
        }

        ... on MSExcelKnowledgeBase {
          id
          title
        }

        ... on MSPPTKnowledgeBase {
          id
          title
        }

        ... on OldMSDocsKnowledgeBase {
          id
          title
        }

        ... on CSVKnowledgeBase {
          id
          title
        }

        ... on HWPKnowledgeBase {
          id
          title
        }
      }

      pageNo
      totalPageCount
      highlightIndexes
      exactAnswer

      draftjs {
        id
        answer
      }

      highlights {
        indexes

        pageIndexes {
          page
          indexes
        }
      }

      answerHighlightId

      knowledgeBaseEntity {
        id
        title
        originalWebUri
      }
    }
  }
`);

type ChatCitationReference = ResultOf<typeof ChatCitationReferenceFragment>;
type ChatKnowledgeBasePreview = Omit<
  NonNullable<ChatCitationReference['knowledgeBasePreview']>,
  'answerHighlightId' | 'knowledgeBaseEntity'
>;

export const getDocumentEntities = ({
  contentState,
  citationReferences,
  knowledgeBasePreview,
  knowledgeBasePreviewKeyPrefix,
  fallbackText = 'Preview',
}: {
  contentState?: ContentState;
  citationReferences?: ChatCitationReference[];
  knowledgeBasePreview?: UserQueryTypes.Maybe<ChatKnowledgeBasePreview>;
  knowledgeBasePreviewKeyPrefix?: string;
  fallbackText?: string;
}) => {
  const result: DraftDocumentEntity[] = [];

  if (contentState) {
    result.push(...DraftDocumentPlugin.getDocumentEntities(contentState));
  }

  if (citationReferences) {
    citationReferences.forEach(cr => {
      if (!cr.knowledgeBasePreview) {
        return;
      }

      const kbTitle =
        cr.knowledgeBasePreview.knowledgeBaseEntity?.title ??
        cr.knowledgeBasePreview.knowledgeBase?.title;

      result.push({
        key: cr.id.toString(),
        text: cr.text ?? (kbTitle ? '' : fallbackText),
        data: {
          ...cr,
          knowledgeBasePreview: {
            ...cr.knowledgeBasePreview,
            knowledgeBase:
              cr.knowledgeBasePreview.knowledgeBaseEntity ??
              cr.knowledgeBasePreview.knowledgeBase,
          },
        },
      });
    });
  }

  if (knowledgeBasePreview?.draftjs || knowledgeBasePreview?.knowledgeBase) {
    result.push({
      key: [
        knowledgeBasePreviewKeyPrefix,
        knowledgeBasePreview.draftjs?.id,
        knowledgeBasePreview.knowledgeBase?.id,
        knowledgeBasePreview.exactAnswer,
        knowledgeBasePreview.pageNo,
        knowledgeBasePreview.totalPageCount,
        knowledgeBasePreview.highlights?.indexes?.join('--'),
        knowledgeBasePreview.highlights?.pageIndexes?.map(pi =>
          [pi.page, pi.indexes.join('----')].join('---'),
        ),
        `kbp-${result.length}`,
      ].join('-'),
      text: knowledgeBasePreview.knowledgeBase?.title ? '' : fallbackText,
      data: {
        knowledgeBasePreview: {
          pageNo: knowledgeBasePreview.pageNo,
          totalPageCount: knowledgeBasePreview.totalPageCount,
          draftjs: knowledgeBasePreview.draftjs,
          highlightIndexes: knowledgeBasePreview.highlightIndexes,
          highlights: knowledgeBasePreview.highlights
            ? omit(
                {
                  ...knowledgeBasePreview.highlights,
                  pageIndexes: knowledgeBasePreview.highlights.pageIndexes?.map(
                    pi => (pi ? omit(pi, '__typename') : pi),
                  ),
                },
                '__typename',
              )
            : knowledgeBasePreview.highlights,
          exactAnswer: knowledgeBasePreview.exactAnswer,
          knowledgeBase: knowledgeBasePreview.knowledgeBase,
        },
      },
    });
  }

  return result;
};

interface UseDocumentEntitiesOptions extends UseRichTextOptions {
  citationReferences?: ChatCitationReference[];
  knowledgeBasePreview?: UserQueryTypes.Maybe<ChatKnowledgeBasePreview>;
  knowledgeBasePreviewKeyPrefix?: string;
}

export const useDocumentEntities = (options: UseDocumentEntitiesOptions) => {
  const {
    citationReferences,
    knowledgeBasePreview,
    knowledgeBasePreviewKeyPrefix,
  } = options;
  const intl = useIntl();
  const { value } = useRichText(options);

  const documentEntities = useMemo(
    () =>
      getDocumentEntities({
        contentState: value?.getCurrentContent(),
        citationReferences,
        knowledgeBasePreview,
        knowledgeBasePreviewKeyPrefix,
        fallbackText: intl.formatMessage(
          {
            id: 'preview',
            defaultMessage: '{count, plural, one {Preview} other {Previews}}',
            description: 'Document preview title missing fallback',
          },
          { count: 1 },
        ),
      }),
    [
      citationReferences,
      intl,
      knowledgeBasePreview,
      knowledgeBasePreviewKeyPrefix,
      value,
    ],
  );

  return {
    documentEntities,
  };
};
