import { ChatFormValues } from '@allganize/alli-chat-form';
import {
  KnowledgeBase,
  KnowledgeBaseSource,
  KnowledgeBaseUploadMenuButton,
  KnowledgeBaseUploadMenuButtonProps,
} from '@allganize/knowledge-base-assistant';
import { forwardRef, Fragment, useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { useMatch } from 'react-router-dom';

import { AssistantKnowledgeBaseSearchDialog } from './assistant-knowledge-base-search-dialog';
import { AssistantKnowledgeBaseUploadDialog } from './assistant-knowledge-base-upload-dialog';
import { analytics } from '../../analytics';

interface AssistantDocumentUploadButtonProps
  extends KnowledgeBaseUploadMenuButtonProps {
  control: Control<ChatFormValues>;
  llmAppId?: string | number;
  conversationId?: string | number;
}

export const AssistantDocumentUploadButton = forwardRef<
  HTMLButtonElement,
  AssistantDocumentUploadButtonProps
>(
  (
    { control, llmAppId, conversationId, onClick, onSelect, ...others },
    ref,
  ) => {
    const routeName = useMatch('/') !== null ? 'home' : 'conversation-detail';
    const {
      field,
      formState: { isSubmitting },
    } = useController({
      control,
      name: 'knowledgeBases',
    });
    const selectedKnowledgeBases = field.value ?? [];
    const deleteKnowledgeBase = (knowledgeBaseId: string | number) => {
      field.onChange(
        field.value?.filter(
          knowledgeBase => knowledgeBase.id !== knowledgeBaseId,
        ),
      );
    };

    const [dialogType, setDialogType] = useState<KnowledgeBaseSource | null>(
      null,
    );
    const closeDialog = () => setDialogType(null);

    useEffect(() => {
      if (dialogType === KnowledgeBaseSource.KnowledgeBase) {
        analytics.track(`${routeName}::chat-input__kb-search-modal.view`, {
          conversationId: conversationId,
          appId: llmAppId,
        });
      }
    }, [dialogType, routeName, conversationId, llmAppId]);

    return (
      <Fragment>
        {dialogType === KnowledgeBaseSource.KnowledgeBase && (
          <AssistantKnowledgeBaseSearchDialog
            open
            selectedKnowledgeBases={selectedKnowledgeBases}
            onClose={(e, reason) => {
              closeDialog();
              analytics.track(
                `${routeName}::chat-input__kb-search-modal.close`,
                {
                  conversationId: conversationId,
                  appId: llmAppId,
                  reason,
                },
              );
            }}
            onConfirm={(knowledgeBases: KnowledgeBase[]) => {
              field.onChange(knowledgeBases);
              closeDialog();
              analytics.track(
                `${routeName}::chat-input__kb-search-modal.confirm`,
                {
                  conversationId: conversationId,
                  appId: llmAppId,
                },
              );
            }}
          />
        )}
        {dialogType === KnowledgeBaseSource.Computer && (
          <AssistantKnowledgeBaseUploadDialog
            open
            onClose={closeDialog}
            onConfirm={(knowledgeBases: KnowledgeBase[]) => {
              field.onChange([...knowledgeBases, ...selectedKnowledgeBases]);
              closeDialog();
            }}
          />
        )}

        <KnowledgeBaseUploadMenuButton
          ref={ref}
          buttonType="icon"
          disabled={isSubmitting}
          onClick={e => {
            onClick?.(e);
            analytics.track(`${routeName}::chat-input__kb-button.click`, {
              conversationId: conversationId,
              appId: llmAppId,
            });
          }}
          onSelect={(type, e) => {
            onSelect?.(type, e);
            setDialogType(type);
            analytics.track(
              `${routeName}::chat-input__kb-button__menu-item.click`,
              {
                conversationId: conversationId,
                appId: llmAppId,
                source: type,
              },
            );
          }}
          onDeleteKnowledgeBase={deleteKnowledgeBase}
          selectedKnowledgeBases={selectedKnowledgeBases}
          {...others}
        />
      </Fragment>
    );
  },
);
