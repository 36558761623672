import {
  KnowledgeBaseUploadDialog,
  KnowledgeBaseUploadDialogProps,
} from '@allganize/knowledge-base-assistant';
import { useMediaQuery } from '@allganize/hooks';
import { useTheme } from '@emotion/react';
import { EmotionCacheProvider } from '../../styles/emotion-cache-provider';
import { emotionCacheOptions } from '../../styles/emotion-cache';

export const AssistantKnowledgeBaseUploadDialog = (
  props: KnowledgeBaseUploadDialogProps,
) => {
  const theme = useTheme();
  const dialogFullScreen = useMediaQuery(
    `(max-width: ${theme.breakpoints.values.sm}px)`,
  );
  return (
    <EmotionCacheProvider options={emotionCacheOptions}>
      <KnowledgeBaseUploadDialog
        {...props}
        container={() => document.getElementById(theme.modalContainer.id)}
        fullScreen={dialogFullScreen}
      />
    </EmotionCacheProvider>
  );
};
