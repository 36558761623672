import {
  ChatFormRow,
  ChatFormRowProps,
  ChatStackItem,
  ChatStackItemProps,
} from '@allganize/alli-chat-base';
import { ResultOf } from '@graphql-typed-document-node/core';
import { FunctionComponent, useMemo } from 'react';
import {
  ContactAgentForm,
  ContactAgentFormProps,
  ContactAgentFormValues,
  getContactAgentFormAgentSelectOption,
} from '../contact-agent-form';
import { gql } from '../gql';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ContactAgent_AssigneeFragment = gql(`
  fragment ContactAgent_AssigneeFragment on Agent {
    id
    firstName
    lastName
    displayName

    avatar {
      id
      url
    }
  }
`);

export interface ContactAgentProps
  extends Pick<ContactAgentFormProps, 'onSubmit' | 'onTyping' | 'validation'>,
    Pick<ChatFormRowProps, 'align' | 'avatar'>,
    Pick<ChatStackItemProps, 'className'> {
  assignee?: ResultOf<typeof ContactAgent_AssigneeFragment> | null;
  disabled?: boolean;
}

export const ContactAgent: FunctionComponent<ContactAgentProps> = props => {
  const {
    align = 'right',
    assignee,
    avatar,
    className,
    disabled,
    onSubmit,
    onTyping,
    validation,
  } = props;

  const defaultValues = useMemo<Partial<ContactAgentFormValues>>(() => {
    return {
      agent: assignee ? getContactAgentFormAgentSelectOption(assignee) : null,
    };
  }, [assignee]);

  if (disabled) {
    return null;
  }

  return (
    <ChatStackItem className={className}>
      <ChatFormRow align={align} avatar={avatar}>
        <ContactAgentForm
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          onTyping={onTyping}
          validation={validation}
        />
      </ChatFormRow>
    </ChatStackItem>
  );
};
