import type { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { DialogProps } from '@allganize/ui-dialog';
import { CreateSlotsAndSlotProps, SlotProps } from '@mui/material/utils/types';
import type { KnowledgeBase } from '../types/types';
import { KnowledgeBaseUploadDialogClasses } from './knowledge-base-upload-dialog-classes';

export interface KnowledgeBaseUploadDialogSlots {
  /**
   * @default Dialog
   */
  dialog?: React.ElementType;
}

export interface KnowledgeBaseUploadDialogSlotProps {
  dialog: SlotProps<React.ElementType<DialogProps>, {}, {}>;
}

export type KnowledgeBaseUploadDialogSlotsAndSlotProps =
  CreateSlotsAndSlotProps<
    KnowledgeBaseUploadDialogSlots,
    KnowledgeBaseUploadDialogSlotProps
  >;

export interface KnowledgeBaseUploadDialogProps
  extends Pick<DialogProps, 'className' | 'onClose' | 'open'>,
    KnowledgeBaseUploadDialogSlotsAndSlotProps {
  /**
   * Override or extend the styles applied to the component.
   */
  classes?: Partial<KnowledgeBaseUploadDialogClasses>;
  onConfirm?: (knowledgeBases: KnowledgeBase[]) => void;
}

export interface UploadingFile extends Partial<KnowledgeBase> {
  processState?: UserQueryTypes.ProcessStateEnum | null;
  file?: File;
  loading?: boolean;
  error?: boolean;
}

export type UploadingFileList = Record<string, UploadingFile>;

export const enum UploadErrorType {
  type = 'type',
  size = 'size',
  process = 'process',
}

export type UploadError = {
  type: UploadErrorType;
  fileNames: string[];
};
