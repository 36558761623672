import { ChatStackItem, ChatStackItemProps } from '@allganize/alli-chat-base';
import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { List } from '@allganize/ui-list';
import { css } from '@emotion/react';
import { ResultOf } from '@graphql-typed-document-node/core';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { gql } from '../gql';
import { CreatedLlmAppOptionItem } from './created-llm-app-option-item';

const avatarWidth = 20;
const gap = 4;

const CreatedLLMAppOptions_ChatFragment = gql(`
  fragment CreatedLLMAppOptions_ChatFragment on Chat {
    ...on BotChat {
      id

      assistantCreatedLlmApp {
        id
        name
        type

        singleActionApp {
          id
          editUrl
          publicToken
        }
      }
    }
  }
`);

export interface CreatedLLMAppOptionsProps
  extends Pick<ChatStackItemProps, 'className'> {
  align?: 'left' | 'right';
  avatar?: React.ReactNode;
  chat: ResultOf<typeof CreatedLLMAppOptions_ChatFragment>;
  onRunApp?(
    app: NonNullable<
      NonNullable<
        Extract<
          ResultOf<typeof CreatedLLMAppOptions_ChatFragment>,
          { __typename: 'BotChat' }
        >['assistantCreatedLlmApp']
      >['singleActionApp']
    >,
  ): void;
}

export const CreatedLLMAppOptions: FunctionComponent<
  CreatedLLMAppOptionsProps
> = props => {
  const {
    align = 'right',
    avatar: avatarProp,
    chat,
    className,
    onRunApp,
  } = props;

  const handleRunApp = () => {
    if (chat.__typename !== 'BotChat') {
      return;
    }

    if (!chat.assistantCreatedLlmApp?.singleActionApp) {
      return;
    }

    onRunApp?.(chat.assistantCreatedLlmApp.singleActionApp);
  };

  if (chat.__typename !== 'BotChat') {
    return null;
  }

  if (!chat.assistantCreatedLlmApp) {
    return null;
  }

  if (
    chat.assistantCreatedLlmApp?.type !==
    UserQueryTypes.LLMAppType.SINGLE_ACTION
  ) {
    return null;
  }

  if (!chat.assistantCreatedLlmApp.singleActionApp?.editUrl && !onRunApp) {
    return null;
  }

  const avatar = avatarProp && (
    <div
      css={css`
        flex-shrink: 0;
        width: ${avatarWidth}px;
        padding: 12px 0;
      `}
    >
      {avatarProp}
    </div>
  );

  return (
    <ChatStackItem className={className}>
      <div
        css={[
          css`
            display: flex;
            align-items: flex-start;
            gap: ${gap}px;
          `,
          align === 'right' &&
            css`
              justify-content: flex-end;
            `,
        ]}
      >
        {align === 'left' && avatar}

        <div
          css={[
            css`
              max-width: 100%;
            `,
            avatarProp &&
              css`
                max-width: calc(100% - ${avatarWidth + gap}px);
              `,
          ]}
        >
          <List
            css={[
              css`
                display: flex;
                gap: 8px;
                flex-direction: column;
                align-items: flex-start;
              `,
              align === 'right' &&
                css`
                  align-items: flex-end;
                `,
            ]}
            disablePadding
            component="div"
          >
            {onRunApp && (
              <CreatedLlmAppOptionItem
                onClick={handleRunApp}
                primary={
                  <FormattedMessage
                    id="assistant-created-llm-app.run-button-primary"
                    defaultMessage="Run App"
                    description="AssistantCreatedLLMApp run primary text"
                  />
                }
                secondary={chat.assistantCreatedLlmApp.name}
              />
            )}

            {chat.assistantCreatedLlmApp.singleActionApp?.editUrl && (
              <CreatedLlmAppOptionItem
                href={chat.assistantCreatedLlmApp.singleActionApp.editUrl}
                target="_blank"
                primary={
                  <FormattedMessage
                    id="assistant-created-llm-app.edit-button-primary"
                    defaultMessage="Edit"
                    description="AssistantCreatedLLMApp edit primary text"
                  />
                }
                secondary={
                  <FormattedMessage
                    id="assistant-created-llm-app.edit-button-secondary"
                    defaultMessage="(Accessible to admin only)"
                    description="AssistantCreatedLLMApp edit secondary text"
                  />
                }
              />
            )}
          </List>
        </div>

        {align === 'right' && avatar}
      </div>
    </ChatStackItem>
  );
};
