import { combineRefs, useEventCallback } from '@allganize/hooks';
import {
  ButtonBase,
  ButtonBaseProps,
  buttonBaseClasses,
} from '@allganize/ui-button';
import {
  IcArrowExpandLess,
  IcArrowExpandMore,
  svgIconClasses,
} from '@allganize/ui-icons';
import {
  ListItemIcon,
  ListItemText,
  listItemIconClasses,
} from '@allganize/ui-list';
import { Menu, MenuItem, menuItemClasses, MenuProps } from '@allganize/ui-menu';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { Tooltip, TooltipProps } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import {
  Fragment,
  MouseEvent,
  ReactNode,
  forwardRef,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

export interface IntentSelectOption {
  label: string | MessageDescriptor;
  description: string | MessageDescriptor;
  value: string | number;
  icon: ReactNode;
}

export interface ChatFormIntentSelectorProps
  extends Omit<ButtonBaseProps, 'value' | 'onChange'> {
  options: IntentSelectOption[];
  value: IntentSelectOption;
  minimize?: boolean;
  menuProps?: Partial<MenuProps>;
  tooltipProps?: Partial<Omit<TooltipProps, 'ref'>>;
  onChange?: (value: string | number | null) => void;
}

export const ChatFormIntentSelector = forwardRef<
  HTMLButtonElement,
  ChatFormIntentSelectorProps
>(
  (
    {
      options,
      value: selectedOption,
      minimize,
      menuProps,
      tooltipProps,
      onChange,
      onClick,
      ...others
    },
    ref,
  ) => {
    const { label, value, icon } = selectedOption;
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const mergedRef = combineRefs(ref, buttonRef);
    const displayLabel =
      typeof label === 'string' ? label : <FormattedMessage {...label} />;

    const handleToggleSelect = useEventCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setOpen(prev => !prev);
        onClick?.(e);
      },
    );

    const handleClose = useEventCallback(() => {
      setOpen(false);
    });

    const handleChange = useEventCallback((opt: IntentSelectOption) => {
      if (opt.value !== value) {
        // use null instead of undefined because of the restriction of react-hook-form
        onChange?.(opt.value ?? null);
      }
      setOpen(false);
    });

    return (
      <Fragment>
        <Tooltip
          {...tooltipProps}
          PopperProps={{
            ...tooltipProps?.PopperProps,
            container: buttonRef.current,
          }}
          title={minimize && !open ? displayLabel : ''}
        >
          <ButtonBase
            ref={mergedRef}
            onClick={handleToggleSelect}
            type="button"
            {...others}
            css={css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(0.5)};
              padding: ${theme.spacing(0.5, 1.5)};
              border-top-left-radius: ${theme.radius.md}px;
              border-top-right-radius: ${theme.radius.md}px;
              border: 1px solid ${theme.palette.border.divider.default};
              border-bottom: 0;
              color: ${theme.palette.foreground.secondary};
              background-color: ${theme.palette.unstable_background.white};
              height: 28px;
              margin: 0 8px 4px -4px;

              &:hover {
                background-color: ${theme.palette.backgroundInteractive
                  .grayGhostAlpha.hover};
              }
              &:active {
                background-color: ${theme.palette.backgroundInteractive
                  .grayGhostAlpha.pressed};
              }
              &.${buttonBaseClasses.disabled} {
                background-color: ${theme.palette.unstable_background
                  .grayDisabled.default};
              }

              .${svgIconClasses.root} {
                font-size: 16px;
              }
            `}
          >
            {icon}
            {!minimize && <Text variant="body14">{displayLabel}</Text>}
            {open ? <IcArrowExpandLess /> : <IcArrowExpandMore />}
          </ButtonBase>
        </Tooltip>
        <Menu
          disableScrollLock
          {...menuProps}
          open={open}
          anchorEl={buttonRef.current}
          onClose={handleClose}
          css={css`
            .${listItemIconClasses.root} {
              color: ${theme.palette.foregroundInteractive.gray.default};
              align-self: flex-start;
              margin-top: 6px;
            }
          `}
        >
          {options.map(option => {
            return (
              <MenuItem
                key={option.value}
                onClick={() => handleChange(option)}
                selected={option.value === value}
                css={css`
                  &.${menuItemClasses.selected} {
                    background-color: ${theme.palette.backgroundInteractive
                      .primarySubtle.selected};
                  }
                `}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText
                  primary={
                    typeof option.label === 'string' ? (
                      option.label
                    ) : (
                      <FormattedMessage {...option.label} />
                    )
                  }
                  secondary={
                    typeof option.description === 'string' ? (
                      option.description
                    ) : (
                      <FormattedMessage {...option.description} />
                    )
                  }
                />
              </MenuItem>
            );
          })}
        </Menu>
      </Fragment>
    );
  },
);
