import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { MessageDescriptor, defineMessages } from 'react-intl';

export const NETWORK_ERROR_STATUS_CODES = [499, 502, 503, 403];

export const mallyErrors = defineMessages({
  UNKNOWN: {
    id: 'MallyError.UNKNOWN',
    defaultMessage: 'Something went wrong. Please try again later.',
    description: 'MallyError.UNKNOWN',
  },
  NETWORK: {
    id: 'Errors.network',
    defaultMessage: 'A network error occurred. Please try again.',
    description: 'Network error message',
  },
}) satisfies Partial<Record<UserQueryTypes.MallyError, MessageDescriptor>>;
