import { ChatStackItem, ChatStackItemProps } from '@allganize/alli-chat-base';
import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { carouselArrowClasses, carouselClasses } from '@allganize/ui-carousel';
import { css } from '@emotion/react';
import { ResultOf } from '@graphql-typed-document-node/core';
import { FunctionComponent, useMemo } from 'react';
import {
  ChatOptionInfoForm,
  ChatOptionInfoFormProps,
  ChatOptionInfoFormValues,
  ChatOptionInfoOption,
  ChatOptionInfoOptionListVariant,
} from '../chat-option-info-form';
import { gql, readFragment } from '../gql';

const horizontalPadding = 12;

const ChatOptionInfo_ChatOptionInfoFragment = gql(`
  fragment ChatOptionInfo_ChatOptionInfoFragment on ChatOptionInfo {
    shortenOption
    longOption
    url
    optionBoxTemplate
    reusable

    urlVariable {
      id
      displayText
    }

    media {
      id
      url
      metaInfo
      mediaType
      width
      height
    }

    style {
      bgColor
      fontColor
      lineColor
      bold
      italic
      underline
    }
  }
`);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChatOptionInfo_ChatFragment = gql(`
  fragment ChatOptionInfo_ChatFragment on Chat {
    ...on BotChat {
      id
      multipleOption

      faqCarousel {
        __typename
      }

      chatOptionInfos {
        ...ChatOptionInfo_ChatOptionInfoFragment
      }
    }

    ...on CarouselChat {
      id
      multipleOption

      chatOptionInfos {
        ...ChatOptionInfo_ChatOptionInfoFragment
      }
    }
  }
`);

export interface ChatOptionInfoProps
  extends Pick<
      ChatOptionInfoFormProps,
      'disabled' | 'isOptionDisabled' | 'onSubmit'
    >,
    Pick<ChatStackItemProps, 'className' | 'disablePadding'> {
  align?: 'left' | 'right';
  chat: ResultOf<typeof ChatOptionInfo_ChatFragment>;
  chatOptionAlign?: UserQueryTypes.ChatOptionAlign;
}

export const ChatOptionInfo: FunctionComponent<ChatOptionInfoProps> = props => {
  const {
    align = 'right',
    chat,
    chatOptionAlign,
    disabled,
    isOptionDisabled,
    onSubmit,
    ...other
  } = props;
  const defaultValues = useMemo<Partial<ChatOptionInfoFormValues>>(() => {
    if (chat.__typename !== 'BotChat' && chat.__typename !== 'CarouselChat') {
      return {};
    }

    return {
      isMulti: chat.multipleOption ?? false,
    };
  }, [chat]);
  const options = useMemo(() => {
    if (chat.__typename !== 'BotChat' && chat.__typename !== 'CarouselChat') {
      return [];
    }

    if (!chat.chatOptionInfos?.length) {
      return [];
    }

    return chat.chatOptionInfos.reduce<ChatOptionInfoOption[]>(
      (acc, curr, i) => {
        const option = readFragment(
          ChatOptionInfo_ChatOptionInfoFragment,
          curr,
        );

        if (!option) {
          return acc;
        }

        return [
          ...acc,
          {
            optionBoxTemplate: option.optionBoxTemplate,
            reusable: option.reusable ?? false,
            value: i,
            text: option.shortenOption || option.longOption,
            url: option.url || option.urlVariable?.displayText || null,
            media: option.media,
            style: option.style,
          },
        ];
      },
      [],
    );
  }, [chat]);
  const variant = useMemo<ChatOptionInfoOptionListVariant>(() => {
    if (chat.__typename !== 'BotChat' && chat.__typename !== 'CarouselChat') {
      return 'list';
    }

    if (chat.__typename === 'CarouselChat') {
      return 'carousel';
    }

    if (
      chatOptionAlign === UserQueryTypes.ChatOptionAlign.HORIZONTAL &&
      !chat.chatOptionInfos?.some(
        info =>
          readFragment(ChatOptionInfo_ChatOptionInfoFragment, info)?.media,
      )
    ) {
      return 'list-wrap';
    }

    return 'list';
  }, [chat, chatOptionAlign]);

  if (chat.__typename !== 'BotChat' && chat.__typename !== 'CarouselChat') {
    return null;
  }

  if (chat.__typename === 'BotChat' && chat.faqCarousel?.length) {
    return null;
  }

  if (!options.length) {
    return null;
  }

  return (
    <ChatStackItem {...other}>
      <ChatOptionInfoForm
        css={css`
          .${carouselArrowClasses.root}.${carouselArrowClasses.prev} {
            right: ${horizontalPadding + 36}px;
          }

          .${carouselArrowClasses.root}.${carouselArrowClasses.next} {
            right: ${horizontalPadding}px;
          }

          .${carouselClasses.list} {
            padding-left: ${horizontalPadding - 4}px;
          }
        `}
        align={align}
        defaultValues={defaultValues}
        disabled={disabled}
        isOptionDisabled={isOptionDisabled}
        onSubmit={onSubmit}
        options={options}
        variant={variant}
      />
    </ChatStackItem>
  );
};
