import { ResultOf } from '@graphql-typed-document-node/core';
import { gql } from '../gql';
import { ChatKnowledgeBasesClasses } from './chat-knowledge-bases.classes';

export const ChatKnowledgeBaseFragment = gql(`
  fragment ChatKnowledgeBaseFragment on KnowledgeBase {
    ... on GoogleDocsKnowledgeBase {
      id
      title
    }
  
    ... on TextKnowledgeBase {
      id
      title
    }
  
    ... on ImageKnowledgeBase {
      id
      title
    }
  
    ... on MSDocsKnowledgeBase {
      id
      title
    }
  
    ... on PDFKnowledgeBase {
      id
      title
    }
  
    ... on MSExcelKnowledgeBase {
      id
      title
    }
  
    ... on MSPPTKnowledgeBase {
      id
      title
    }
  
    ... on OldMSDocsKnowledgeBase {
      id
      title
    }
  
    ... on CSVKnowledgeBase {
      id
      title
    }
  
    ... on HWPKnowledgeBase {
      id
      title
    }
  }
`);

export type ChatKnowledgeBase = ResultOf<typeof ChatKnowledgeBaseFragment>;

export interface ChatKnowledgeBasesProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Override or extend the styles applied to the component.
   */
  classes?: Partial<ChatKnowledgeBasesClasses>;
  data: ChatKnowledgeBase[];
}
