import { PdfPageStyles } from '@allganize/alli-document';
import { ModalContext, TypingContext } from '@allganize/alli-sdk-chat';
import { MessengerIframe } from '@allganize/alli-sdk-ui';
import { AlliModalContainer } from '@allganize/alli-sdk/alli-modal-container/alli-modal-container';
import { useAlliModalContainer } from '@allganize/alli-sdk/alli-modal-container/use-alli-modal-container';
import { ChatListProvider } from '@allganize/alli-sdk/chat-list/chat-list-provider';
import { config as sdkConfig } from '@allganize/alli-sdk/config';
import { ConversationDetailErrorState } from '@allganize/alli-sdk/conversation-detail/conversation-detail-error-state';
import { ConversationDetailLoadingState } from '@allganize/alli-sdk/conversation-detail/conversation-detail-loading-state';
import { ConversationProvider } from '@allganize/alli-sdk/conversation-detail/conversation-provider';
import { DocumentPreviewContext } from '@allganize/alli-sdk/document-preview/document-preview-context';
import { useDocumentPreview } from '@allganize/alli-sdk/document-preview/use-document-preview';
import { useTyping } from '@allganize/alli-sdk/hooks/use-typing';
import { loadMessages, MessageIds } from '@allganize/alli-sdk/i18n/locales';
import { Preview } from '@allganize/alli-sdk/preview/preview';
import { PreviewContext } from '@allganize/alli-sdk/preview/preview-context';
import { usePreview } from '@allganize/alli-sdk/preview/use-preview';
import { useProject } from '@allganize/alli-sdk/project/use-project';
import { SingleActionPreviewContext } from '@allganize/alli-sdk/single-action-preview/single-action-preview-context';
import { useSingleActionPreview } from '@allganize/alli-sdk/single-action-preview/use-single-action-preview';
import { CssBaseline } from '@allganize/alli-sdk/styles/css-baseline';
import { EmotionCacheProvider } from '@allganize/alli-sdk/styles/emotion-cache-provider';
import { DraftLinkContext } from '@allganize/draft-link-plugin';
import { CarouselStyles } from '@allganize/ui-carousel';
import { Global, css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Fragment,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { IntlProvider } from 'react-intl';
import { Outlet, useParams } from 'react-router-dom';
import { useLocale } from '../../i18n/use-locale';
import fontStyles from '../../styles/fonts.css?url';
import { Footer } from '../conversation/footer';
import { Grow } from '../layout/styled-components';

export const contentMaxWidth = 806;

const AppContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;
  position: relative;
`;

const StyledPreview = styled(Preview)`
  width: 0;
  margin-right: 0;
`;

const MessengerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 380px;
`;

const ContentIframe = styled(MessengerIframe)`
  flex-grow: 1;
  position: static;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: ${contentMaxWidth}px;
  flex: 1 0 auto;
`;

export const ConversationDetailLayout = () => {
  const theme = useTheme();
  const { conversationId } = useParams<{ conversationId: string }>();
  const { locale } = useLocale();
  const project = useProject();
  const alliModalContainer = useAlliModalContainer();
  const preview = usePreview();
  const previewContextProps = {
    previewSidebarAnchor: preview.previewSidebarAnchor,
    shouldOpenPreviewInDialog: preview.shouldOpenPreviewInDialog,
    setPreviewType: preview.setPreviewType,
  };
  const documentPreview = useDocumentPreview(previewContextProps);
  const singleActionPreview = useSingleActionPreview(previewContextProps);
  const onTyping = useTyping({ conversationId });
  const draftLinkContextValue = useContext(DraftLinkContext);
  const { getLinkTarget: getLinkTargetParent } = draftLinkContextValue;
  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);
  const [messages, setMessages] =
    useState<Partial<Record<MessageIds, string>>>();

  useEffect(() => {
    loadMessages(locale).then(msgs => {
      setMessages(msgs);
    });
  }, [locale]);

  const emotionCacheOptions = useMemo(
    () => ({
      key: 'alli-sdk-messenger',
      container: iframeRef?.contentWindow?.document.head,
    }),
    [iframeRef?.contentWindow?.document.head],
  );
  const getLinkTarget = useCallback(
    (href?: string) => {
      const parentValue = getLinkTargetParent(href);

      if (parentValue === '_self') {
        return '_parent';
      }

      return parentValue;
    },
    [getLinkTargetParent],
  );
  const iframeFixedWidth =
    preview.previewOpen && !preview.shouldOpenPreviewInDialog;

  if (!conversationId) {
    throw new Error('conversationId is required');
  }

  if (!project) {
    return null;
  }

  return (
    <IntlProvider locale={locale} defaultLocale={locale} messages={messages}>
      <ModalContext.Provider value={alliModalContainer}>
        <PreviewContext.Provider value={preview}>
          <SingleActionPreviewContext.Provider value={singleActionPreview}>
            <DocumentPreviewContext.Provider value={documentPreview}>
              <PdfPageStyles />
              <AppContainer className="alli-app">
                <StyledPreview
                  variants={{
                    open: {
                      width: 'auto',
                      opacity: 1,
                      flexGrow: 1,
                    },
                    closed: {
                      width: 0,
                      opacity: 0,
                      flexGrow: 0,
                    },
                  }}
                />
                <MessengerContainer
                  css={
                    iframeFixedWidth &&
                    css`
                      flex-grow: 0;
                    `
                  }
                >
                  <ContentIframe
                    name="alli-messenger-container"
                    portalKey="alli-messenger-container"
                    ref={setIframeRef}
                    role="dialog"
                    allowFullScreen
                    id={sdkConfig.messenger.containerId}
                    css={[
                      iframeFixedWidth &&
                        css`
                          width: ${theme.messenger.width}px;
                        `,
                    ]}
                    head={
                      <Fragment>
                        <base target="_parent" />
                        <link href={fontStyles} rel="stylesheet" />
                      </Fragment>
                    }
                  >
                    <EmotionCacheProvider options={emotionCacheOptions}>
                      <Global
                        styles={css`
                          body {
                            display: flex;
                            overflow: auto;
                            padding: 0 !important; // [ALL-17039] Fix a bug where body padding was added when the intent-selector was opened on a specific device.
                          }
                        `}
                      />
                      <CssBaseline />
                      <CarouselStyles />

                      <DraftLinkContext.Provider
                        value={{ ...draftLinkContextValue, getLinkTarget }}
                      >
                        <ErrorBoundary
                          fallbackRender={({ resetErrorBoundary }) => (
                            <Content>
                              <ConversationDetailErrorState
                                conversationId={conversationId}
                                onRetry={resetErrorBoundary}
                              />
                            </Content>
                          )}
                        >
                          <Suspense
                            fallback={
                              <Content
                                css={css`
                                  padding-bottom: 72px;
                                `}
                              >
                                <Grow />
                                <ConversationDetailLoadingState />
                              </Content>
                            }
                          >
                            <ConversationProvider
                              conversationId={conversationId}
                            >
                              <ChatListProvider conversationId={conversationId}>
                                <TypingContext.Provider value={{ onTyping }}>
                                  <Content>
                                    <Outlet />
                                    <Footer />
                                  </Content>
                                </TypingContext.Provider>
                              </ChatListProvider>
                            </ConversationProvider>
                          </Suspense>
                        </ErrorBoundary>
                      </DraftLinkContext.Provider>
                    </EmotionCacheProvider>
                  </ContentIframe>
                </MessengerContainer>
                <AlliModalContainer />
              </AppContainer>
            </DocumentPreviewContext.Provider>
          </SingleActionPreviewContext.Provider>
        </PreviewContext.Provider>
      </ModalContext.Provider>
    </IntlProvider>
  );
};
