import { combineRefs } from '@allganize/hooks';
import { Button, ButtonProps, IconButton } from '@allganize/ui-button';
import {
  IcAdd,
  IcAttachment,
  IcFile,
  IcKnowledgeBase,
} from '@allganize/ui-icons';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef, Fragment, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { knowledgeBaseUploadButtonClasses } from './knowledge-base-upload-button-classes';
import {
  KnowledgeBaseSource,
  KnowledgeBaseUploadMenuButtonProps,
} from './knowledge-base-upload-button-type-map';
import { KnowledgeBaseUploadMenu } from './knowledge-base-upload-menu';
import { KnowledgeBaseCountBadge } from './knowledge-base-upload-count-badge';

export const KnowledgeBaseUploadMenuButton = forwardRef<
  HTMLButtonElement,
  KnowledgeBaseUploadMenuButtonProps
>(
  (
    {
      classes,
      buttonType = 'default',
      selectedKnowledgeBases,
      menuProps,
      onSelect,
      onClick,
      onDeleteKnowledgeBase,
      ...others
    },
    ref,
  ) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const mergedRef = combineRefs(buttonRef, ref);

    const [open, setOpen] = useState(menuProps?.open ?? false);

    const handleOpen = (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      setOpen(true);
      onClick?.(ev);
    };

    const handleClose = (ev: React.MouseEvent) => {
      ev.stopPropagation();
      setOpen(false);
    };

    const handleClickItem = (
      value: KnowledgeBaseSource,
      ev: React.MouseEvent,
    ) => {
      ev.stopPropagation();
      onSelect?.(value, ev);
      setOpen(false);
    };

    return (
      <Fragment>
        {buttonType === 'icon' ? (
          <IconButton
            variant={selectedKnowledgeBases?.length ? 'outlined' : 'ghost'}
            shape="rounded"
            size="large"
            ref={mergedRef}
            onClick={handleOpen}
            {...others}
            className={clsx(
              knowledgeBaseUploadButtonClasses.root,
              classes?.root,
              others.className,
            )}
            css={css`
              position: relative;
            `}
          >
            <IcAttachment />
            <KnowledgeBaseCountBadge
              count={selectedKnowledgeBases?.length}
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(-50%, -50%);
              `}
            />
          </IconButton>
        ) : (
          <Button
            size="small"
            startIcon={<IcAdd />}
            variant="outlined"
            ref={mergedRef}
            onClick={handleOpen}
            {...others}
            className={clsx(
              knowledgeBaseUploadButtonClasses.root,
              classes?.root,
              others.className,
            )}
          >
            <FormattedMessage
              id="knowledge-base.upload-menu-button.label"
              defaultMessage="Add documents"
              description="assistant document fallback add documents button label text"
            />
          </Button>
        )}
        {buttonRef.current && (
          <KnowledgeBaseUploadMenu
            {...menuProps}
            className={clsx(
              knowledgeBaseUploadButtonClasses.menu,
              menuProps?.className,
            )}
            open={open}
            onClose={handleClose}
            anchorEl={buttonRef.current}
            selectedKnowledgeBases={selectedKnowledgeBases}
            onSelect={handleClickItem}
            onDeleteKnowledgeBase={onDeleteKnowledgeBase}
          />
        )}
      </Fragment>
    );
  },
);

export const KnowledgeBaseAddButton = (props: ButtonProps) => {
  return (
    <Button
      size="small"
      variant="outlined"
      startIcon={<IcKnowledgeBase fontSize="small" />}
      {...props}
    >
      <FormattedMessage
        id="knowledge-base.add-button.label"
        defaultMessage="Add from Knowledge base"
        description="assistant document fallback add from knowledge base button label text"
      />
    </Button>
  );
};

export const KnowledgeBaseUploadButton = (props: ButtonProps) => {
  return (
    <Button
      size="small"
      variant="outlined"
      startIcon={<IcFile fontSize="small" />}
      {...props}
    >
      <FormattedMessage
        id="knowledge-base.upload-button.label"
        defaultMessage="Upload from computer"
        description="assistant document fallback upload from computer button label text"
      />
    </Button>
  );
};
