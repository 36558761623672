/* eslint-disable */
import * as UserQueryTypes from '@allganize/data-access-alli-user-query';

import { SingleActionAppResultFragment } from '../fragments/single-action-app-result-fragment';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SingleActionAppResultPublicSubscriptionVariables =
  UserQueryTypes.Exact<{
    where: UserQueryTypes.ProjectWhereUniqueInput;
    accessToken: UserQueryTypes.Scalars['String']['input'];
    publicToken: UserQueryTypes.Scalars['String']['input'];
  }>;

export type SingleActionAppResultPublicSubscription = {
  __typename: 'UserSubscription';
} & {
  singleActionAppResultPublic: UserQueryTypes.Maybe<
    { __typename: 'SingleActionAppResult' } & SingleActionAppResultFragment
  >;
};

export const SingleActionAppResultPublicSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'SingleActionAppResultPublicSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectWhereUniqueInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accessToken' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'publicToken' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'singleActionAppResultPublic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectWhere' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accessToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accessToken' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'publicToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SingleActionAppResultFragment',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SingleActionAppResultFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SingleActionAppResult' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'output' } },
          { kind: 'Field', name: { kind: 'Name', value: 'outputOriginal' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isComplete' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'documentResults' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'output' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dynamicInputsItemName' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SingleActionAppResultPublicSubscription,
  SingleActionAppResultPublicSubscriptionVariables
>;
