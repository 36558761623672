import { ButtonProps, IconButtonProps } from '@allganize/ui-button';
import { MenuProps } from '@allganize/ui-menu';
import { knowledgeBaseUploadButtonClasses } from './knowledge-base-upload-button-classes';
import { KnowledgeBase } from '../types/types';
import { KnowledgeBaseUploadMenuProps } from './knowledge-base-upload-menu';

export const enum KnowledgeBaseSource {
  KnowledgeBase = 'KnowledgeBase',
  Computer = 'Computer',
}

export interface KnowledgeBaseUploadMenuButtonProps
  extends Omit<ButtonProps, 'onSelect' | 'classes'>,
    Omit<IconButtonProps, 'onSelect' | 'classes'> {
  classes?: Partial<typeof knowledgeBaseUploadButtonClasses>;
  buttonType?: 'icon' | 'default';
  menuProps?: Partial<Omit<MenuProps, 'anchorEl'>>;
  selectedKnowledgeBases?: KnowledgeBase[];
  onSelect?: (value: KnowledgeBaseSource, ev: React.MouseEvent) => void;
  onDeleteKnowledgeBase?: KnowledgeBaseUploadMenuProps['onDeleteKnowledgeBase'];
}
