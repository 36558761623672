import { DraftDocumentContext } from '@allganize/draft-document-plugin';
import { Chip } from '@allganize/ui-chip';
import { CircularProgress } from '@allganize/ui-circular-progress';
import clsx from 'clsx';
import { forwardRef, useContext, useState } from 'react';
import { draftDocumentListItemClasses } from './draft-document-list-item.classes';
import { DraftDocumentListItemProps } from './draft-document-list-item.types';

export const DraftDocumentListItem = forwardRef<
  HTMLDivElement,
  DraftDocumentListItemProps
>((props, ref) => {
  const { classes, entity, onClick: onClickProp, ...other } = props;
  const { isSelected, onClick } = useContext(DraftDocumentContext);
  const [loading, setLoading] = useState(false);
  const selected = isSelected({ entity });
  const clickable = onClickProp || onClick;

  const handleClick = async (ev?: React.MouseEvent<HTMLDivElement>) => {
    if (ev) {
      onClickProp?.(ev);
    }

    if (loading) {
      return;
    }

    try {
      setLoading(true);
      await onClick?.({ entity });
    } catch {
      // noop
    }

    setLoading(false);
  };

  return (
    <Chip
      color={selected ? 'gray-dark' : 'gray-subtle'}
      icon={loading ? <CircularProgress size="xs" /> : undefined}
      disabled={loading}
      label={[
        entity.text,
        entity.data.knowledgeBasePreview.knowledgeBase?.title,
      ]
        .filter(Boolean)
        .join(' ')}
      {...other}
      ref={ref}
      onClick={clickable ? handleClick : undefined}
      className={clsx(
        draftDocumentListItemClasses.root,
        {
          [draftDocumentListItemClasses.loading]: loading,
          [draftDocumentListItemClasses.selected]: selected,
        },
        classes?.root,
        {
          [classes?.loading ?? '']: loading,
          [classes?.selected ?? '']: selected,
        },
        other.className,
      )}
    />
  );
});
