import { DraftLinkContext } from '@allganize/draft-link-plugin';
import { useImageLoaded } from '@allganize/hooks';
import { Truncate } from '@allganize/truncate';
import { CircularProgress } from '@allganize/ui-circular-progress';
import { IcDescription } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { Link, linkClasses, LinkProps } from '@allganize/ui-link';
import { css } from '@emotion/react';
import { ResultOf } from '@graphql-typed-document-node/core';
import clsx from 'clsx';
import { FunctionComponent, useContext } from 'react';
import { FragmentType, gql, readFragment } from '../gql';
import {
  LinkImageCarouselItemClasses,
  linkImageCarouselItemClasses,
} from './link-image-carousel-item.classes';

export const LinkImageCarouselItem_CarouselOptionFragment = gql(`
  fragment LinkImageCarouselItem_CarouselOptionFragment on CarouselOption {
    id
    url
    media {
      id
      url
    }
    primary {
      text
        style {
          color
        }
    }
    secondary {
      text
        style {
          color
        }
    }
    tertiary {
      text
        style {
          color
        }
    }
  }
`);

export interface LinkImageCarouselItemProps
  extends Pick<LinkProps, 'className'> {
  classes?: Partial<LinkImageCarouselItemClasses>;
  option: FragmentType<typeof LinkImageCarouselItem_CarouselOptionFragment>;
  onClick?(
    option: ResultOf<typeof LinkImageCarouselItem_CarouselOptionFragment>,
  ): void;
}

export const LinkImageCarouselItem: FunctionComponent<
  LinkImageCarouselItemProps
> = props => {
  const { classes, className, onClick, option: optionProp } = props;
  const option = readFragment(
    LinkImageCarouselItem_CarouselOptionFragment,
    optionProp,
  );
  const { media, url, primary, secondary, tertiary } = option;
  const theme = useTheme();
  const thumbnailUrl = media?.url;
  const loaded = useImageLoaded({ src: thumbnailUrl || null });
  const target = useContext(DraftLinkContext).getLinkTarget(url || undefined);

  const handleClick = () => {
    onClick?.(option);
  };

  return (
    <Link
      data-testid="recommended-item"
      href={url || undefined}
      rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      target={target}
      underline="hover"
      onClick={handleClick}
      css={css`
        width: 200px;
        background-color: ${theme.palette.unstable_background.white};
        display: inline-flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        border-radius: ${theme.radius.sm}px;
        border: 1px solid ${theme.palette.border.divider.default};
        overflow: hidden;
        color: unset;

        &:hover,
        &.${linkClasses.focusVisible} {
          .${linkImageCarouselItemClasses.imageContainer} {
            &::after {
              background-color: ${theme.palette.backgroundInteractive
                .graySubtleAlpha.hover};
            }
          }

          .${linkImageCarouselItemClasses.primary},
            .${linkImageCarouselItemClasses.secondary},
            .${linkImageCarouselItemClasses.tertiary} {
            text-decoration: underline;
          }
        }

        &:active {
          .${linkImageCarouselItemClasses.imageContainer} {
            &::after {
              background-color: ${theme.palette.backgroundInteractive
                .graySubtleAlpha.pressed};
            }
          }
        }
      `}
      className={clsx(
        linkImageCarouselItemClasses.root,
        classes?.root,
        className,
      )}
    >
      <div
        css={css`
          position: relative;
          width: 100%;
          height: 188px;
          background-color: ${theme.palette.backgroundInteractive
            .graySubtleAlpha.default};
          display: flex;
          align-items: center;
          justify-content: center;

          &::after {
            display: block;
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            pointer-events: none;
            transition: ${theme.transitions.create('background-color', {
              duration: theme.transitions.duration.short,
            })};
          }
        `}
        className={clsx(
          linkImageCarouselItemClasses.imageContainer,
          classes?.imageContainer,
        )}
      >
        {loaded === 'loaded' && (
          <img
            css={css`
              display: block;
              width: 100%;
              height: 100%;
              user-select: none;
              object-fit: cover;
              object-position: top center;
              word-break: break-all;
              background-color: ${theme.palette.unstable_background.white};
            `}
            src={thumbnailUrl}
            alt={primary?.text}
            className={clsx(linkImageCarouselItemClasses.image, classes?.image)}
          />
        )}

        {loaded === 'error' && (
          <span
            css={css`
              color: ${theme.palette.foreground.secondary};
              font-size: 36px;
            `}
          >
            <IcDescription color="inherit" fontSize="inherit" />
          </span>
        )}

        {!loaded && <CircularProgress color="primary" />}
      </div>
      <div
        className={clsx(
          linkImageCarouselItemClasses.contentContainer,
          classes?.contentContainer,
        )}
        css={css`
          padding: 8px 12px;
          text-align: left;
        `}
      >
        {primary && (
          <Text
            className={clsx(
              linkImageCarouselItemClasses.primary,
              classes?.primary,
            )}
            variant="title12"
            noWrap
            css={css`
              color: ${primary.style?.color ||
              theme.palette.foreground.default};
            `}
          >
            {primary.text}
          </Text>
        )}
        {secondary && (
          <Text variant="body12" component="div">
            <Truncate
              className={clsx(
                linkImageCarouselItemClasses.secondary,
                classes?.secondary,
              )}
              clamp={2}
              css={css`
                color: ${secondary.style?.color ||
                theme.palette.foreground.secondary};
              `}
            >
              {secondary.text}
            </Truncate>
          </Text>
        )}
        {tertiary && (
          <Text
            className={clsx(
              linkImageCarouselItemClasses.tertiary,
              classes?.tertiary,
            )}
            variant="title14"
            component="div"
            css={css`
              color: ${tertiary.style?.color ||
              theme.palette.foreground.default};
            `}
            noWrap
          >
            {tertiary.text}
          </Text>
        )}
      </div>
    </Link>
  );
};
