/* eslint-disable */
import * as UserQueryTypes from '@allganize/data-access-alli-user-query';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type KnowledgeBaseHashtagsQueryVariables = UserQueryTypes.Exact<{
  limit?: UserQueryTypes.InputMaybe<UserQueryTypes.Scalars['Int']['input']>;
  offset?: UserQueryTypes.InputMaybe<UserQueryTypes.Scalars['Int']['input']>;
  searchTerm?: UserQueryTypes.InputMaybe<
    UserQueryTypes.Scalars['String']['input']
  >;
}>;

export type KnowledgeBaseHashtagsQuery = { __typename: 'UserQuery' } & {
  knowledgeBaseHashtags: UserQueryTypes.Maybe<
    { __typename: 'KnowledgeBaseHashtags' } & {
      hashtags: Array<
        UserQueryTypes.Maybe<
          { __typename: 'KnowledgeBaseHashtag' } & Pick<
            UserQueryTypes.KnowledgeBaseHashtag,
            'hashtag'
          >
        >
      >;
    }
  >;
};

export const KnowledgeBaseHashtagsQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'KnowledgeBaseHashtagsQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchTerm' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'knowledgeBaseHashtags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTerm' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'searchTerm' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'hashtags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hashtag' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  KnowledgeBaseHashtagsQuery,
  KnowledgeBaseHashtagsQueryVariables
>;
