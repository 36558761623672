import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { toast } from '@allganize/ui-toast';
import { useMutation, useSubscription } from '@apollo/client/react';
import { compact } from 'lodash-es';
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { AppContext } from './app-context';
import { GenerateInput, OutputContext } from './output-context';
import { SingleActionAppResultPublicSubscriptionDocument } from '../../graphql/subscriptions/single-action-app-result-public-subscription';
import { UpdateSingleActionAppResultPublicMutationDocument } from '../../graphql/mutations/update-single-action-app-result-public-mutation';
import { GenerateSingleActionAppResultPublicMutationDocument } from '../../graphql/mutations/generate-single-action-app-result-public-mutation';
import { StopSingleActionAppResultPublicMutationDocument } from '../../graphql/mutations/stop-single-action-app-result-public-mutation';
import { SendSingleActionAppResultToConversationMutationDocument } from '../../graphql/mutations/send-single-action-app-result-to-conversation-mutation';
import { getMallyErrorMessageDescriptor } from '../utils/error';

interface OutputProviderProps {
  conversationIdToSendResult: string;
  children?: ReactNode;
  onGenerate?: () => void;
}

export const OutputProvider: FunctionComponent<OutputProviderProps> = ({
  conversationIdToSendResult,
  children,
  onGenerate,
}) => {
  const intl = useIntl();
  const {
    data: appData,
    publicToken,
    accessToken,
    generateAccessToken,
  } = useContext(AppContext);
  const appId = appData?.id || '';
  const projectId = appData?.projectId || '';

  const { data } = useSubscription(
    SingleActionAppResultPublicSubscriptionDocument,
    {
      skip: !publicToken || !accessToken || !projectId,
      variables: {
        where: { id: projectId },
        publicToken,
        accessToken,
      },
      onError: err =>
        toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(err))),
    },
  );
  const isComplete = data?.singleActionAppResultPublic?.isComplete;

  const [sendResultToConversation] = useMutation(
    SendSingleActionAppResultToConversationMutationDocument,
    {
      onCompleted: res => {
        const error = compact(
          res.sendSingleActionAppResultToConversation?.errors,
        )[0];
        if (error) {
          toast.error(
            intl.formatMessage(getMallyErrorMessageDescriptor(error)),
          );
        }
      },
      onError: err =>
        toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(err))),
    },
  );

  useEffect(() => {
    const singleActionAppResultId = data?.singleActionAppResultPublic?.id;
    if (isComplete && conversationIdToSendResult && singleActionAppResultId) {
      sendResultToConversation({
        variables: {
          conversationId: conversationIdToSendResult,
          singleActionAppResultId,
        },
      });
    }
  }, [isComplete]);

  const outputData = useMemo(
    () => ({
      id: data?.singleActionAppResultPublic?.id || '',
      output: data?.singleActionAppResultPublic?.output || '',
      outputOriginal: data?.singleActionAppResultPublic?.outputOriginal || '',
      documentResults: data?.singleActionAppResultPublic?.documentResults || [],
    }),
    [data?.singleActionAppResultPublic],
  );

  const [mutate, { loading, called, reset }] = useMutation(
    GenerateSingleActionAppResultPublicMutationDocument,
    {
      onCompleted: res => {
        const error = compact(
          res.generateSingleActionAppResultPublic?.errors,
        )[0];

        if (error) {
          const errorMessage = intl.formatMessage(
            getMallyErrorMessageDescriptor(error),
          );

          if (error.key === UserQueryTypes.MallyError.INVALID_ACCESS_TOKEN) {
            toast.warning(errorMessage);
            generateAccessToken();
          } else {
            toast.error(errorMessage);
          }
        }
      },
      onError: err =>
        toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(err))),
    },
  );

  useEffect(() => {
    reset();
  }, [publicToken, reset]);

  const startGenerate = useCallback(
    ({
      userInput,
      singleActionAppSelectedDocumentInputInfo,
    }: GenerateInput) => {
      mutate({
        variables: {
          where: { id: projectId },
          appId: appId,
          accessToken,
          publicToken,
          userInput,
          singleActionAppSelectedDocumentInputInfo,
        },
      });
      onGenerate?.();
    },
    [projectId, appId, accessToken, publicToken, mutate, onGenerate],
  );

  const [stopGenerate] = useMutation(
    StopSingleActionAppResultPublicMutationDocument,
    {
      variables: {
        where: { id: projectId },
        publicToken,
        singleActionAppResultId: outputData.id,
      },
      onCompleted: res => {
        const error = compact(res.stopSingleActionAppResultPublic?.errors)[0];
        if (error) {
          toast.error(
            intl.formatMessage(getMallyErrorMessageDescriptor(error)),
          );
        }
      },
      onError: err =>
        toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(err))),
    },
  );

  const [updateMutate, { loading: updating }] = useMutation(
    UpdateSingleActionAppResultPublicMutationDocument,
    {
      onCompleted: res => {
        const error = compact(res.updateSingleActionAppResultPublic?.errors)[0];
        if (error) {
          toast.error(
            intl.formatMessage(getMallyErrorMessageDescriptor(error)),
          );
        }
      },
      onError: err =>
        toast.error(intl.formatMessage(getMallyErrorMessageDescriptor(err))),
    },
  );

  const update = useCallback(
    async (updatedOutput: string) => {
      await updateMutate({
        variables: {
          where: {
            id: projectId,
          },
          resultId: outputData.id,
          updatedOutput,
          accessToken,
          token: publicToken,
        },
      });
    },
    [projectId, accessToken, publicToken, outputData.id, updateMutate],
  );

  const [generateDisabled, setGenerateDisabled] = useState(false);

  const onCopy = useCallback(() => {
    toast.success(
      intl.formatMessage({
        id: 'single-action.final-output.copy-toast',
        defaultMessage: 'Copied to clipboard',
        description: 'single action final output copy toast message',
      }),
    );
  }, [intl]);

  const output = useMemo(() => {
    return {
      data: outputData,
      called,
      loading,
      updating,
      updated: outputData.output !== outputData.outputOriginal,
      update,
      revert: () => update(outputData.outputOriginal),
      onCopy,
      stopGenerate,
      startGenerate,
      setGenerateDisabled: (disabled: boolean) => setGenerateDisabled(disabled),
      generateDisabled,
    };
  }, [
    outputData,
    called,
    loading,
    updating,
    update,
    onCopy,
    stopGenerate,
    startGenerate,
    setGenerateDisabled,
    generateDisabled,
  ]);

  return (
    <OutputContext.Provider value={output}>{children}</OutputContext.Provider>
  );
};
