import {
  KnowledgeBaseCard,
  KnowledgeBaseCardContainer,
} from '@allganize/knowledge-base-assistant';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { ChatKnowledgeBasesProps } from './chat-knowledge-bases.types';
import { chatKnowledgeBasesClasses } from './chat-knowledge-bases.classes';

export const ChatKnowledgeBases = forwardRef<
  HTMLDivElement,
  ChatKnowledgeBasesProps
>(({ classes, data, ...others }, ref) => {
  const theme = useTheme();

  return (
    <KnowledgeBaseCardContainer
      css={css`
        margin-top: 12px;
        display: flex;
        flex-wrap: wrap;
      `}
      ref={ref}
      {...others}
      className={clsx(
        chatKnowledgeBasesClasses.root,
        classes?.root,
        others.className,
      )}
    >
      {data.map(knowledgeBase => {
        const data = {
          id: knowledgeBase.id,
          type: knowledgeBase.__typename,
          fileName: knowledgeBase.title,
        };
        return (
          <KnowledgeBaseCard
            key={knowledgeBase.id}
            css={css`
              width: 224px;
              min-width: 100%;

              ${theme.breakpoints.up(theme.breakpoints.values.sm)} {
                min-width: calc(${100 / 2}% - ${8 / 2}px);
              }
              ${theme.breakpoints.up(theme.breakpoints.values.md)} {
                min-width: calc(${100 / 3}% - ${16 / 3}px);
              }
            `}
            data={data}
            className={clsx(
              chatKnowledgeBasesClasses.knowledgeBase,
              classes?.knowledgeBase,
            )}
          />
        );
      })}
    </KnowledgeBaseCardContainer>
  );
});
