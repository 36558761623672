import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { format, isValid } from 'date-fns';
import { filesize } from 'filesize';
import { defineMessages, IntlShape } from 'react-intl';
import { z } from 'zod';

import type {
  ChatFormErrorFieldName,
  ChatFormValidationExtended,
  ChatFormValues,
} from '../types/types';

export const dateFormat = 'yyyy-MM-dd';

export const enabledFields: Record<
  keyof ChatFormValues,
  UserQueryTypes.ChatInputType[]
> = {
  message: [
    UserQueryTypes.ChatInputType.BOOLEAN,
    UserQueryTypes.ChatInputType.NUMBER,
    UserQueryTypes.ChatInputType.STRING,
  ],
  file: [UserQueryTypes.ChatInputType.FILE, UserQueryTypes.ChatInputType.IMAGE],
  date: [UserQueryTypes.ChatInputType.DATETIME],
  intent: [
    UserQueryTypes.ChatInputType.BOOLEAN,
    UserQueryTypes.ChatInputType.NUMBER,
    UserQueryTypes.ChatInputType.STRING,
    UserQueryTypes.ChatInputType.FILE,
    UserQueryTypes.ChatInputType.IMAGE,
    UserQueryTypes.ChatInputType.DATETIME,
  ], // always enabled
  knowledgeBases: [
    UserQueryTypes.ChatInputType.BOOLEAN,
    UserQueryTypes.ChatInputType.NUMBER,
    UserQueryTypes.ChatInputType.STRING,
    UserQueryTypes.ChatInputType.FILE,
    UserQueryTypes.ChatInputType.IMAGE,
    UserQueryTypes.ChatInputType.DATETIME,
  ], // always enabled
};

const errorMessages = defineMessages({
  fieldRequired: {
    id: 'form.errors.required',
    defaultMessage: 'This field is required.',
    description: 'Field required error message',
  },
  fieldInvalidFormat: {
    id: 'form.errors.invalid-format',
    defaultMessage: 'Invalid format',
    description: 'Field invalid format error message',
  },
  fileExtensions: {
    id: 'form.file.errors.type',
    defaultMessage:
      'Invalid file type. Allowed file types include the following: {extensions}',
    description: 'File extensions error message',
  },
  maxFileSize: {
    id: 'form.file.errors.max',
    defaultMessage: 'File too large (Max. {size})',
    description: 'File too large error message',
  },
});

export const createValidationSchema = (
  intl: IntlShape,
  options: ChatFormValidationExtended,
  win?: typeof globalThis,
) => {
  const FileKlass = win?.File ?? File;
  const messageFieldEnabled =
    options.nextInputType.length === 0 ||
    options.nextInputType.findIndex(type =>
      enabledFields.message.includes(type),
    ) >= 0;
  const fileFieldEnabled =
    options.nextInputType.findIndex(type =>
      enabledFields.file.includes(type),
    ) >= 0;
  const imageFileOnly =
    fileFieldEnabled &&
    !options.nextInputType.includes(UserQueryTypes.ChatInputType.FILE);
  const dateFieldEnabled =
    options.nextInputType.findIndex(type =>
      enabledFields.date.includes(type),
    ) >= 0;
  const messageFieldOnly =
    messageFieldEnabled &&
    options.nextInputType.findIndex(
      type => !enabledFields.message.includes(type),
    ) < 0;
  const messageNumberOnly =
    messageFieldEnabled &&
    options.nextInputType.length > 0 &&
    options.nextInputType.findIndex(
      type => type !== UserQueryTypes.ChatInputType.NUMBER,
    ) < 0;
  const fileFieldOnly =
    fileFieldEnabled &&
    options.nextInputType.findIndex(
      type => !enabledFields.file.includes(type),
    ) < 0;
  const dateFieldOnly =
    dateFieldEnabled &&
    options.nextInputType.findIndex(
      type => !enabledFields.date.includes(type),
    ) < 0;

  return z
    .object({
      message: z.string().refine(schema => {
        if (!messageNumberOnly) {
          return true;
        }

        if (!schema) {
          return true;
        }

        const numeric = Number(schema);
        return !Number.isNaN(numeric);
      }, intl.formatMessage(errorMessages['fieldInvalidFormat'])),
      file: z
        .union([z.instanceof(File), z.instanceof(FileKlass)], {
          invalid_type_error: intl.formatMessage(
            errorMessages['fieldInvalidFormat'],
          ),
        })
        .nullable()
        .refine(schema => {
          if (!schema) {
            return true;
          }

          if (!imageFileOnly) {
            return true;
          }

          return schema.type.startsWith('image/');
        }, intl.formatMessage(errorMessages['fieldInvalidFormat']))
        .refine(schema => {
          if (!schema) {
            return true;
          }

          if (
            !(options.file.extensions && options.file.extensions.length > 0)
          ) {
            return true;
          }

          const extension = schema.name.split('.').pop();
          return (
            extension &&
            options.file.extensions.includes(`.${extension.toLowerCase()}`)
          );
        }, intl.formatMessage(errorMessages['fileExtensions'], { type: options.file.extensions?.join(', ') }))
        .refine(
          schema => {
            if (!fileFieldEnabled) {
              return true;
            }

            if (!schema) {
              return true;
            }

            if (schema.type.startsWith('image/')) {
              return schema.size <= options.file.maxSize.image;
            }

            return schema.size <= options.file.maxSize.default;
          },
          schema => {
            if (schema?.type.startsWith('image/')) {
              return {
                message: intl.formatMessage(errorMessages['maxFileSize'], {
                  size: filesize(options.file.maxSize.image, {
                    output: 'string',
                    locale: intl.locale,
                    localeOptions: intl.formats.date,
                  }),
                }),
              };
            }

            return {
              message: intl.formatMessage(errorMessages['maxFileSize'], {
                size: filesize(options.file.maxSize.default, {
                  output: 'string',
                  locale: intl.locale,
                  localeOptions: intl.formats.date,
                }),
              }),
            };
          },
        ),
      date: z
        .date()
        .nullable()
        .refine(schema => {
          if (!dateFieldEnabled) {
            return true;
          }

          if (!schema) {
            return true;
          }

          return isValid(schema);
        }, intl.formatMessage(errorMessages['fieldInvalidFormat'])),
      intent: z.string().optional().nullable(),
      knowledgeBases: z
        .object({
          id: z.union([z.string(), z.number()]),
          type: z.string(),
          fileName: z.string(),
          description: z.string().optional().nullable(),
        })
        .array()
        .optional()
        .nullable(),
    })
    .refine(
      schema => {
        if (!messageFieldEnabled && !fileFieldEnabled && !dateFieldEnabled) {
          return true;
        }

        if (fileFieldEnabled && schema.file !== null) {
          return true;
        }

        if (dateFieldEnabled && schema.date !== null) {
          return true;
        }

        if (messageFieldEnabled && schema.message.replace(/\s/g, '')) {
          return true;
        }

        return false;
      },
      {
        path: (fileFieldOnly && ['file']) ||
          (dateFieldOnly && ['date']) ||
          (messageFieldOnly && ['message']) || ['root'],
        message: intl.formatMessage(errorMessages['fieldRequired']),
      },
    )
    .refine(
      schema => {
        if (!options.customValidation) {
          return true;
        }

        const regExp = new RegExp(options.customValidation.regex);

        if (dateFieldEnabled && schema.date !== null) {
          return regExp.test(format(schema.date, dateFormat));
        }

        if (messageFieldEnabled) {
          return regExp.test(schema.message);
        }

        return true;
      },
      schema => {
        return {
          path:
            dateFieldEnabled && schema.date !== null ? ['date'] : ['message'],
          message:
            options.customValidation?.message ??
            intl.formatMessage(errorMessages['fieldInvalidFormat']),
        };
      },
    );
};

const errorFieldNames: ChatFormErrorFieldName[] = [
  'root',
  'message',
  'file',
  'date',
  'knowledgeBases',
];

export const isErrorFieldName = (field: any): field is ChatFormErrorFieldName =>
  errorFieldNames.includes(field);
