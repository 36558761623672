/* eslint-disable */
import * as Types from '@allganize/alli-sdk-interfaces';

import { ProjectFragment } from '../fragments/project-fragment';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ProjectQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ProjectQuery = { __typename: 'UserQuery' } & {
  project: Types.Maybe<{ __typename: 'Project' } & ProjectFragment>;
};

export const ProjectQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectFragment' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SDKSettingsMediaFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Media' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metaInfo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resizedUrls' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'argument' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '64' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '64' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '128' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '128' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '192' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '192' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SDKSettingsFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SDKSettings' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyLogo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SDKSettingsMediaFragment' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'showContactAgentButton' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'showSendConversationButton' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasNewConversationButton' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasBackButton' } },
          { kind: 'Field', name: { kind: 'Name', value: 'themeColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bgColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chatBubblePosition' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'useAutoComplete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numAutoComplete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'chatBubbleOffsetX' } },
          { kind: 'Field', name: { kind: 'Name', value: 'chatBubbleOffsetY' } },
          { kind: 'Field', name: { kind: 'Name', value: 'chatWindowWidth' } },
          { kind: 'Field', name: { kind: 'Name', value: 'chatWindowHeight' } },
          { kind: 'Field', name: { kind: 'Name', value: 'popUpWidth' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'popUpCustomMessage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'popUpUseCustomMessage' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'chatOptionAlign' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SDKUrlPatternFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SDKUrlPattern' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urlPattern' } },
          { kind: 'Field', name: { kind: 'Name', value: 'regex' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LLMAppFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'LLMApp' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          { kind: 'Field', name: { kind: 'Name', value: 'icon' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaign' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignToken' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'singleActionApp' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publicToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'editUrl' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Project' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enabledAddOns' } },
          { kind: 'Field', name: { kind: 'Name', value: 'apiKey' } },
          { kind: 'Field', name: { kind: 'Name', value: 'usageType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'useAppLauncherNavigationBar' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'useAppLauncherInSdk' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'useCustomAssistantEntryLlmApp' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hideAlliBranding' } },
          { kind: 'Field', name: { kind: 'Name', value: 'useCautionMessage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sdkSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SDKSettingsFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sdkUrlPatterns' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SDKUrlPatternFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assistantEntryLlmApp' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'LLMAppFragment' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProjectQuery, ProjectQueryVariables>;
