import { useWindow } from '@allganize/hooks';
import { Avatar } from '@allganize/ui-avatar';
import { ListItemAvatar, ListItemText } from '@allganize/ui-list';
import {
  components,
  HighlightedText,
  SelectField,
  SelectFieldProps,
} from '@allganize/ui-select';
import { css } from '@emotion/react';
import { FunctionComponent } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FormatOptionLabelMeta, MultiValueProps } from 'react-select';
import { AgentSelectOption, FormValueFormValues } from './use-form-value-form';

const formatOptionLabel = (
  option: AgentSelectOption,
  formatOptionLabelMeta: FormatOptionLabelMeta<AgentSelectOption>,
) => {
  if (formatOptionLabelMeta.context !== 'menu') {
    return option.label;
  }

  const avatar = (
    <ListItemAvatar>
      <Avatar size="smallest" src={option.avatar} alt={option.label}>
        {option.label.charAt(0)}
      </Avatar>
    </ListItemAvatar>
  );

  return (
    <>
      {avatar}

      <ListItemText
        css={css`
          word-break: break-all;
        `}
        primary={
          <HighlightedText
            text={option.label}
            query={formatOptionLabelMeta.inputValue}
            options={{ insideWords: true, findAllOccurrences: true }}
          />
        }
      />
    </>
  );
};

const MultiValue = (props: MultiValueProps<AgentSelectOption, true>) => {
  return (
    <components.MultiValue
      {...props}
      avatar={
        <Avatar size="smallest" src={props.data.avatar} alt={props.data.label}>
          {props.data.label.charAt(0)}
        </Avatar>
      }
    />
  );
};

export interface TargetAgentsFieldProps
  extends Pick<SelectFieldProps<AgentSelectOption, true>, 'options'> {
  disabled?: boolean;
}

export const TargetAgentsField: FunctionComponent<
  TargetAgentsFieldProps
> = props => {
  const { disabled: disabledProp, options } = props;
  const { ref: rootRef, window: win } = useWindow();
  const { control } = useFormContext<FormValueFormValues>();
  const {
    field: { ref: fieldRef, ...field },
    fieldState,
    formState,
  } = useController({
    control,
    name: 'targetAgents',
  });
  const disabled = disabledProp || field.disabled || formState.disabled;

  if (disabled) {
    return null;
  }

  return (
    <SelectField
      {...field}
      selectRef={fieldRef}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      options={options}
      isMulti
      fullWidth
      label={
        <FormattedMessage
          id="agent"
          defaultMessage="{count, plural, one {Agent} other {Agents}}"
          description="form value form agent field label"
          values={{ count: 0 }}
        />
      }
      placeholder={
        <FormattedMessage
          id="select.placeholder"
          defaultMessage="Select..."
          description="Select placeholder"
        />
      }
      ref={rootRef}
      SelectProps={{
        components: { MultiValue },
        formatOptionLabel,
        menuPortalTarget: win?.document.body,
      }}
    />
  );
};
