import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type KnowledgeBaseUploadButtonClassKey = 'root' | 'menu';

export type KnowledgeBaseUploadButtonClasses = Record<
  KnowledgeBaseUploadButtonClassKey,
  string
>;

export const knowledgeBaseUploadButtonClasses: KnowledgeBaseUploadButtonClasses =
  generateUtilityClasses('KnowledgeBaseUploadButton', ['root', 'menu']);
