import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { useEventCallback } from '@allganize/hooks';
import {
  AlliGraphQLError,
  useFormatErrorMessage,
} from '@allganize/utils-graphql';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { FieldPath, useForm } from 'react-hook-form';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { z } from 'zod';

export interface ChatOptionInfoOption {
  readonly optionBoxTemplate: UserQueryTypes.OptionBoxTemplate | null;
  readonly reusable: boolean;
  value: number;
  text: string;
  url: string | null;
  media: Pick<
    UserQueryTypes.Media,
    '__typename' | 'id' | 'url' | 'metaInfo' | 'width' | 'height' | 'mediaType'
  > | null;
  style: Pick<
    UserQueryTypes.OptionStyle,
    | '__typename'
    | 'bgColor'
    | 'bold'
    | 'fontColor'
    | 'italic'
    | 'lineColor'
    | 'underline'
  > | null;
}

export interface ChatOptionInfoFormValues {
  isMulti: boolean;
  single: ChatOptionInfoOption | null;
  multiple: ChatOptionInfoOption[];
}

const defaultChatOptionInfoFormValues: ChatOptionInfoFormValues = {
  isMulti: false,
  single: null,
  multiple: [],
};

type ChatOptionInfoFormErrorField =
  | FieldPath<ChatOptionInfoFormValues>
  | 'root';
const chatOptionInfoFormErrorFields: ChatOptionInfoFormErrorField[] = [
  'isMulti',
  'single',
  'multiple',
  'root',
];

const isChatOptionInfoFormErrorField = (
  field: any,
): field is ChatOptionInfoFormErrorField =>
  chatOptionInfoFormErrorFields.includes(field);

const errorMessages = defineMessages({
  'single-required': {
    id: 'form.errors.required',
    defaultMessage: 'This field is required.',
    description: 'Field required error message',
  },
  'multiple-required': {
    id: 'form.errors.required',
    defaultMessage: 'This field is required.',
    description: 'Field required error message',
  },
});

const createValidationSchema = (intl: IntlShape) => {
  const chatOptionInfoOptionSchema = z.object({
    optionBoxTemplate: z
      .nativeEnum(UserQueryTypes.OptionBoxTemplate)
      .nullable(),
    reusable: z.boolean(),
    value: z.number(),
    text: z.string(),
    url: z.string().nullable(),
    media: z
      .object({
        __typename: z.literal('Media'),
        id: z.union([z.string(), z.number()]),
        url: z.string(),
        metaInfo: z.string().nullable(),
        width: z.number().nullable(),
        height: z.number().nullable(),
        mediaType: z.string().nullable(),
      })
      .nullable(),
    style: z
      .object({
        __typename: z.literal('OptionStyle'),
        bgColor: z.string().nullable(),
        bold: z.boolean().nullable(),
        fontColor: z.string().nullable(),
        italic: z.boolean().nullable(),
        lineColor: z.string().nullable(),
        underline: z.boolean().nullable(),
      })
      .nullable(),
  }) satisfies z.ZodSchema<ChatOptionInfoOption>;

  return (
    z.object({
      isMulti: z.boolean(),
      single: chatOptionInfoOptionSchema.nullable(),
      multiple: z.array(chatOptionInfoOptionSchema),
    }) satisfies z.ZodSchema<ChatOptionInfoFormValues>
  ).refine(
    value => {
      if (!value.isMulti) {
        return true;
      }

      return value.multiple.length > 0;
    },
    {
      path: ['multiple'],
      message: intl.formatMessage(errorMessages['multiple-required']),
    },
  );
};

export interface UseChatOptionInfoFormOptions {
  defaultValues?: Partial<ChatOptionInfoFormValues>;
  disabled?: boolean;
  onSubmit?(values: ChatOptionInfoFormValues): Promise<void>;
}

export const useChatOptionInfoForm = ({
  defaultValues,
  disabled,
  onSubmit,
}: UseChatOptionInfoFormOptions) => {
  const intl = useIntl();
  const formatErrorMessage = useFormatErrorMessage();
  const validationSchema = useMemo(
    () => zodResolver(createValidationSchema(intl)),
    [intl],
  );

  const form = useForm<ChatOptionInfoFormValues>({
    mode: 'all',
    defaultValues: {
      ...defaultChatOptionInfoFormValues,
      ...defaultValues,
    },
    disabled,
    resolver: validationSchema,
  });

  const { reset, setError } = form;

  const submit = useEventCallback(async (values: ChatOptionInfoFormValues) => {
    try {
      await onSubmit?.(values);

      if (!values.isMulti) {
        reset();
      }
    } catch (err) {
      if (err instanceof AlliGraphQLError) {
        const errorGroups = err.groupErrorsByField({
          intl,
          isErrorField: isChatOptionInfoFormErrorField,
        });

        // Set the errors in the form using setError
        Object.entries(errorGroups).forEach(([field, errors]) => {
          if (!errors) {
            return;
          }

          setError(field as ChatOptionInfoFormErrorField, {
            message: errors[0]?.message,
          });
        });

        return;
      }

      if (err instanceof Error) {
        setError('root', {
          message: formatErrorMessage(err),
        });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }

      setError('root', {
        message: formatErrorMessage(),
      });
    }
  });

  return { form, submit };
};
