import { DocumentViewer } from '@allganize/alli-document';
import { Truncate } from '@allganize/truncate';
import { IconButton } from '@allganize/ui-button';
import { IcOpenInNew } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { Tooltip } from '@allganize/ui-tooltip';
import { css } from '@emotion/react';
import { Fragment, FunctionComponent, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { analytics } from '../analytics';
import { PreviewContext } from '../preview/preview-context';
import { PreviewCloseButton } from '../preview/preview-close-button';
import { DocumentPreviewContext } from './document-preview-context';

export const DocumentPreview: FunctionComponent = () => {
  const { previewSidebarAnchor, shouldOpenPreviewInDialog } =
    useContext(PreviewContext);
  const {
    documentPreviewOpen,
    documentPreviewState,
    documentViewerRef,
    closeDocumentPreview,
  } = useContext(DocumentPreviewContext);

  useEffect(() => {
    if (!documentPreviewOpen) {
      return;
    }

    analytics?.track('view_document_preview', {
      mode: shouldOpenPreviewInDialog ? 'dialog' : 'sidebar',
      previewSidebarAnchor,
      chatId: documentPreviewState?.chat.id,
      entityText: documentPreviewState?.entity.text,
      url: documentPreviewState?.entity.data.url ?? undefined,
    });
  }, [
    documentPreviewOpen,
    previewSidebarAnchor,
    documentPreviewState?.chat.id,
    documentPreviewState?.entity.data.url,
    documentPreviewState?.entity.text,
    shouldOpenPreviewInDialog,
  ]);

  if (!documentPreviewState) {
    return null;
  }

  return (
    <DocumentViewer
      css={css`
        height: 100%;
        width: 100%;
      `}
      initialPage={documentPreviewState.initialPage}
      pages={documentPreviewState.pages}
      thumbnails={documentPreviewState.thumbnails}
      header={
        <Fragment>
          <div
            css={css`
              flex-grow: 1;
              display: flex;
              align-items: center;
            `}
          >
            <Text variant="title12">
              <Truncate clamp={1}>{documentPreviewState.title}</Truncate>
            </Text>
            {documentPreviewState.originalWebUri && (
              <Tooltip
                title={
                  <FormattedMessage
                    id="document-preview.original-web-uri"
                    defaultMessage="Open page in a new tab"
                    description="Document preview original web uri tooltip"
                  />
                }
              >
                <IconButton
                  component="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={documentPreviewState.originalWebUri}
                >
                  <IcOpenInNew />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <PreviewCloseButton
            edge="end"
            onClick={() => closeDocumentPreview('closeButtonClick')}
          />
        </Fragment>
      }
      viewerRef={documentViewerRef}
    />
  );
};
