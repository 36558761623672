import generateUtilityClasses from '@mui/material/generateUtilityClasses';

export type ChatKnowledgeBasesClassKey = 'root' | 'knowledgeBase';
export type ChatKnowledgeBasesClasses = Record<
  ChatKnowledgeBasesClassKey,
  string
>;

export const chatKnowledgeBasesClasses: ChatKnowledgeBasesClasses =
  generateUtilityClasses('ChatKnowledgeBases', ['root', 'knowledgeBase']);
