/* eslint-disable */
import * as Types from '@allganize/data-access-alli-user-query';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type { Incremental } from '@allganize/data-access-alli-user-query';

type CreatedLLMAppOptions_ChatFragment_AgentChat_Fragment = { __typename: 'AgentChat' } & { ' $fragmentName'?: 'CreatedLLMAppOptions_ChatFragment_AgentChat_Fragment' };

type CreatedLLMAppOptions_ChatFragment_BotChat_Fragment = (
  { __typename: 'BotChat' }
  & Pick<Types.BotChat, 'id'>
  & { assistantCreatedLlmApp: Types.Maybe<(
    { __typename: 'LLMApp' }
    & Pick<Types.LLMApp, 'id' | 'name' | 'type'>
    & { singleActionApp: Types.Maybe<(
      { __typename: 'SingleActionApp' }
      & Pick<Types.SingleActionApp, 'id' | 'editUrl' | 'publicToken'>
    )> }
  )> }
) & { ' $fragmentName'?: 'CreatedLLMAppOptions_ChatFragment_BotChat_Fragment' };

type CreatedLLMAppOptions_ChatFragment_CarouselChat_Fragment = { __typename: 'CarouselChat' } & { ' $fragmentName'?: 'CreatedLLMAppOptions_ChatFragment_CarouselChat_Fragment' };

type CreatedLLMAppOptions_ChatFragment_ContactAgentByEmailChat_Fragment = { __typename: 'ContactAgentByEmailChat' } & { ' $fragmentName'?: 'CreatedLLMAppOptions_ChatFragment_ContactAgentByEmailChat_Fragment' };

type CreatedLLMAppOptions_ChatFragment_CustomUIChat_Fragment = { __typename: 'CustomUIChat' } & { ' $fragmentName'?: 'CreatedLLMAppOptions_ChatFragment_CustomUIChat_Fragment' };

type CreatedLLMAppOptions_ChatFragment_EventChat_Fragment = { __typename: 'EventChat' } & { ' $fragmentName'?: 'CreatedLLMAppOptions_ChatFragment_EventChat_Fragment' };

type CreatedLLMAppOptions_ChatFragment_FillSlotRichUXChat_Fragment = { __typename: 'FillSlotRichUXChat' } & { ' $fragmentName'?: 'CreatedLLMAppOptions_ChatFragment_FillSlotRichUXChat_Fragment' };

type CreatedLLMAppOptions_ChatFragment_SendFormChat_Fragment = { __typename: 'SendFormChat' } & { ' $fragmentName'?: 'CreatedLLMAppOptions_ChatFragment_SendFormChat_Fragment' };

type CreatedLLMAppOptions_ChatFragment_UserChat_Fragment = { __typename: 'UserChat' } & { ' $fragmentName'?: 'CreatedLLMAppOptions_ChatFragment_UserChat_Fragment' };

export type CreatedLLMAppOptions_ChatFragmentFragment = CreatedLLMAppOptions_ChatFragment_AgentChat_Fragment | CreatedLLMAppOptions_ChatFragment_BotChat_Fragment | CreatedLLMAppOptions_ChatFragment_CarouselChat_Fragment | CreatedLLMAppOptions_ChatFragment_ContactAgentByEmailChat_Fragment | CreatedLLMAppOptions_ChatFragment_CustomUIChat_Fragment | CreatedLLMAppOptions_ChatFragment_EventChat_Fragment | CreatedLLMAppOptions_ChatFragment_FillSlotRichUXChat_Fragment | CreatedLLMAppOptions_ChatFragment_SendFormChat_Fragment | CreatedLLMAppOptions_ChatFragment_UserChat_Fragment;

export type LLMAppCarouselItem_LLMAppFragmentFragment = (
  { __typename: 'LLMApp' }
  & Pick<Types.LLMApp, 'id' | 'icon' | 'name' | 'description'>
) & { ' $fragmentName'?: 'LLMAppCarouselItem_LLMAppFragmentFragment' };

type LLMAppCarousel_ChatFragment_AgentChat_Fragment = { __typename: 'AgentChat' } & { ' $fragmentName'?: 'LLMAppCarousel_ChatFragment_AgentChat_Fragment' };

type LLMAppCarousel_ChatFragment_BotChat_Fragment = (
  { __typename: 'BotChat' }
  & Pick<Types.BotChat, 'id'>
  & { assistantRecommendedApps: Types.Maybe<Array<(
    { __typename: 'LLMApp' }
    & Pick<Types.LLMApp, 'id'>
    & { ' $fragmentRefs'?: { 'LLMAppCarouselItem_LLMAppFragmentFragment': LLMAppCarouselItem_LLMAppFragmentFragment } }
  )>> }
) & { ' $fragmentName'?: 'LLMAppCarousel_ChatFragment_BotChat_Fragment' };

type LLMAppCarousel_ChatFragment_CarouselChat_Fragment = { __typename: 'CarouselChat' } & { ' $fragmentName'?: 'LLMAppCarousel_ChatFragment_CarouselChat_Fragment' };

type LLMAppCarousel_ChatFragment_ContactAgentByEmailChat_Fragment = { __typename: 'ContactAgentByEmailChat' } & { ' $fragmentName'?: 'LLMAppCarousel_ChatFragment_ContactAgentByEmailChat_Fragment' };

type LLMAppCarousel_ChatFragment_CustomUIChat_Fragment = { __typename: 'CustomUIChat' } & { ' $fragmentName'?: 'LLMAppCarousel_ChatFragment_CustomUIChat_Fragment' };

type LLMAppCarousel_ChatFragment_EventChat_Fragment = { __typename: 'EventChat' } & { ' $fragmentName'?: 'LLMAppCarousel_ChatFragment_EventChat_Fragment' };

type LLMAppCarousel_ChatFragment_FillSlotRichUXChat_Fragment = { __typename: 'FillSlotRichUXChat' } & { ' $fragmentName'?: 'LLMAppCarousel_ChatFragment_FillSlotRichUXChat_Fragment' };

type LLMAppCarousel_ChatFragment_SendFormChat_Fragment = { __typename: 'SendFormChat' } & { ' $fragmentName'?: 'LLMAppCarousel_ChatFragment_SendFormChat_Fragment' };

type LLMAppCarousel_ChatFragment_UserChat_Fragment = { __typename: 'UserChat' } & { ' $fragmentName'?: 'LLMAppCarousel_ChatFragment_UserChat_Fragment' };

export type LLMAppCarousel_ChatFragmentFragment = LLMAppCarousel_ChatFragment_AgentChat_Fragment | LLMAppCarousel_ChatFragment_BotChat_Fragment | LLMAppCarousel_ChatFragment_CarouselChat_Fragment | LLMAppCarousel_ChatFragment_ContactAgentByEmailChat_Fragment | LLMAppCarousel_ChatFragment_CustomUIChat_Fragment | LLMAppCarousel_ChatFragment_EventChat_Fragment | LLMAppCarousel_ChatFragment_FillSlotRichUXChat_Fragment | LLMAppCarousel_ChatFragment_SendFormChat_Fragment | LLMAppCarousel_ChatFragment_UserChat_Fragment;

export const CreatedLLMAppOptions_ChatFragmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CreatedLLMAppOptions_ChatFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Chat"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BotChat"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"assistantCreatedLlmApp"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"singleActionApp"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"editUrl"}},{"kind":"Field","name":{"kind":"Name","value":"publicToken"}}]}}]}}]}}]}}]} as unknown as DocumentNode<CreatedLLMAppOptions_ChatFragmentFragment, unknown>;
export const LLMAppCarouselItem_LLMAppFragmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LLMAppCarouselItem_LLMAppFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LLMApp"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"icon"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}}]} as unknown as DocumentNode<LLMAppCarouselItem_LLMAppFragmentFragment, unknown>;
export const LLMAppCarousel_ChatFragmentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LLMAppCarousel_ChatFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Chat"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BotChat"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"assistantRecommendedApps"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"LLMAppCarouselItem_LLMAppFragment"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"LLMAppCarouselItem_LLMAppFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LLMApp"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"icon"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}}]} as unknown as DocumentNode<LLMAppCarousel_ChatFragmentFragment, unknown>;