import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { isArray, isObject, reduce } from 'lodash-es';
import { Option, SingleActionInput } from '../contexts/app-context';
import { DocumentInput } from '../contexts/output-context';

type UserInput = SingleActionInput & {
  value: string | Option | Option[];
};

export interface SingleActionFormValues {
  userInput: UserInput[];
}

const isDocumentInputValue = (
  inputType: UserQueryTypes.SingleActionInputType,
  value: UserInput['value'],
): value is Option => {
  return (
    inputType === UserQueryTypes.SingleActionInputType.DOCUMENT_BASIC ||
    inputType === UserQueryTypes.SingleActionInputType.DOCUMENT_ADVANCED ||
    inputType === UserQueryTypes.SingleActionInputType.IMAGE_BASIC ||
    inputType === UserQueryTypes.SingleActionInputType.IMAGE_ADVANCED
  );
};

export const getGenerateInput = (values: SingleActionFormValues) => {
  const singleActionAppSelectedDocumentInputInfo: DocumentInput[] = [];
  const result = reduce(
    values.userInput,
    (acc, { dynamicInputsItemName, value, inputType, id }) => {
      if (isDocumentInputValue(inputType, value)) {
        singleActionAppSelectedDocumentInputInfo.push({
          knowledgeBaseId: value.value,
          singleActionInputId: id,
        });
        return { ...acc, [dynamicInputsItemName]: '' };
      }

      if (isArray(value)) {
        return {
          ...acc,
          [dynamicInputsItemName]: (value as Option[])
            .map(op => op.value)
            .join(','),
        };
      }
      if (isObject(value)) {
        return { ...acc, [dynamicInputsItemName]: (value as Option).value };
      }
      return { ...acc, [dynamicInputsItemName]: value };
    },
    {},
  );
  return {
    userInput: JSON.stringify(result),
    singleActionAppSelectedDocumentInputInfo,
  };
};
