import { TypingContext } from '@allganize/alli-sdk-chat';
import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { useWindow } from '@allganize/hooks';
import { FileDropzoneProps } from '@allganize/ui-file-input';
import { AlliGraphQLError } from '@allganize/utils-graphql';
import { ResultOf } from '@graphql-typed-document-node/core';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { compact, some } from 'lodash-es';
import { useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  dateFormat,
  createValidationSchema,
  enabledFields,
  isErrorFieldName,
} from '../utils/chat-form';
import {
  CONVERSATION_STATE_ENDED,
  CONVERSATION_STATE_WAIT_USER_ANSWER,
} from '../constants/conversation';
import {
  ChatFormErrorFieldName,
  ChatFormValidationExtended,
  ChatFormValues,
} from '../types/types';
import { validation } from '../config/validation';
import { mallyErrors } from '../i18n/mally-errors';
import { gql } from '../gql';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChatForm__ChatFragment = gql(`
  fragment ChatForm__ChatFragment on Chat {
    ... on AgentChat {
      nextInputType
    }
    ... on BotChat {
      nextInputType
      placeholder
      generatingState
      allowKnowledgeBaseFile {
        extensions
        maxSize
      }
      saveAsVariable {
        validationCustom
        validationFailedMessage
      }
    }
    ... on CarouselChat {
      nextInputType
      placeholder
    }
    ... on ContactAgentByEmailChat {
      nextInputType
    }
    ... on SendFormChat {
      nextInputType
    }
    ... on UserChat {
      nextInputType
    }
  }
`);

const defaultValues: ChatFormValues = {
  message: '',
  file: null,
  date: null,
};

export interface UseChatFormOptions {
  conversationState?: UserQueryTypes.ConversationStateEnum;
  lastChat: ResultOf<typeof ChatForm__ChatFragment> | null;
  hasNonCompletedEdges: boolean;
  onSubmit: (values: ChatFormValues) => void;
}

export const useChatForm = ({
  conversationState,
  lastChat,
  hasNonCompletedEdges,
  onSubmit,
}: UseChatFormOptions) => {
  const intl = useIntl();
  const { ref: formRef, window: win } = useWindow();

  const conversationEnded =
    conversationState && CONVERSATION_STATE_ENDED.includes(conversationState);
  const waitUserAnswer =
    conversationState &&
    CONVERSATION_STATE_WAIT_USER_ANSWER.includes(conversationState);
  const allowKnowledgeBaseFile =
    lastChat?.__typename === 'BotChat' ? lastChat.allowKnowledgeBaseFile : null;
  const nextInputType = useMemo(
    () =>
      lastChat &&
      lastChat.__typename !== 'EventChat' &&
      lastChat.__typename !== 'CustomUIChat' &&
      lastChat.__typename !== 'FillSlotRichUXChat'
        ? compact(lastChat.nextInputType)
        : [],
    [lastChat],
  );
  const messageFieldEnabled =
    nextInputType.length === 0 ||
    nextInputType.findIndex(type => enabledFields.message.includes(type)) >= 0;
  const fileFieldEnabled =
    nextInputType.findIndex(type => enabledFields.file.includes(type)) >= 0;
  const dateFieldEnabled =
    nextInputType.findIndex(type => enabledFields.date.includes(type)) >= 0;
  const disabled =
    conversationEnded ||
    hasNonCompletedEdges ||
    !(messageFieldEnabled || fileFieldEnabled || dateFieldEnabled);
  const isGenerating =
    lastChat?.__typename === 'BotChat' &&
    !!lastChat.generatingState &&
    lastChat.generatingState !== UserQueryTypes.SearchContextStep.COMPLETE;

  const validationExtended = useMemo<ChatFormValidationExtended>(
    () => ({
      ...validation,
      file: {
        ...validation.file,
        extensions: (
          allowKnowledgeBaseFile?.extensions ?? validation.file.extensions
        )?.map(ext => ext.toLowerCase()),
        maxSize:
          allowKnowledgeBaseFile?.maxSize === null ||
          typeof allowKnowledgeBaseFile?.maxSize === 'undefined'
            ? validation.file.maxSize
            : {
                default: allowKnowledgeBaseFile.maxSize,
                image: allowKnowledgeBaseFile.maxSize,
              },
      },
      nextInputType,
      customValidation:
        lastChat?.__typename === 'BotChat' &&
        lastChat.saveAsVariable?.validationCustom
          ? {
              regex: lastChat.saveAsVariable.validationCustom,
              message:
                lastChat.saveAsVariable.validationFailedMessage ?? undefined,
            }
          : undefined,
    }),
    [allowKnowledgeBaseFile, lastChat, nextInputType],
  );

  const validationSchema = useMemo(
    () =>
      zodResolver(
        createValidationSchema(intl, validationExtended, win ?? undefined),
      ),
    [intl, validationExtended, win],
  );

  const form = useForm<ChatFormValues>({
    mode: 'all',
    defaultValues,
    resolver: validationSchema,
  });

  const {
    formState: { isSubmitting },
    resetField,
    setError,
    watch,
  } = form;
  const formDisabled = disabled || isSubmitting || isGenerating;

  const { onTyping } = useContext(TypingContext);

  useEffect(() => {
    const subscription = watch(values => onTyping(some(values, v => !!v)));
    return () => subscription.unsubscribe();
  }, [onTyping, watch]);

  const acceptImage = nextInputType.includes(
    UserQueryTypes.ChatInputType.IMAGE,
  );
  const acceptFile = nextInputType.includes(UserQueryTypes.ChatInputType.FILE);
  const acceptImageOnly = acceptImage && !acceptFile;
  const accept = acceptImageOnly ? { 'image/*': [] } : undefined;

  const dropzoneOptions = {
    accept,
    maxFiles: 1,
    noClick: true,
  } satisfies FileDropzoneProps;

  const submit = async (values: ChatFormValues) => {
    try {
      let message = values.message;

      if (dateFieldEnabled && values.date !== null) {
        message = format(values.date, dateFormat);
      }

      await onSubmit({ ...values, message });

      resetField('message');
      resetField('file');
      resetField('date');
      resetField('knowledgeBases');
    } catch (err) {
      if (err instanceof AlliGraphQLError) {
        const errorGroups = err.groupErrorsByField({
          intl,
          isErrorField: isErrorFieldName,
        });

        // Set the errors in the form using setError
        Object.entries(errorGroups).forEach(([field, errors]) => {
          setError(field as ChatFormErrorFieldName, {
            message: errors?.[0].message,
          });
        });

        return;
      }

      if (err instanceof Error) {
        setError('root', { message: err.message });
        return;
      }

      if (typeof err === 'string') {
        setError('root', { message: err });
        return;
      }

      setError('root', {
        message: intl.formatMessage(mallyErrors.UNKNOWN),
      });
    }
  };

  const placeholder =
    ((lastChat?.__typename === 'BotChat' ||
      lastChat?.__typename === 'CarouselChat') &&
      lastChat.placeholder) ||
    '';

  return {
    disabled,
    dateFieldEnabled,
    fileFieldEnabled,
    messageFieldEnabled,
    dropzoneOptions,
    form,
    formDisabled,
    formRef,
    nextInputType,
    placeholder,
    submit,
    waitUserAnswer,
    isGenerating,
  };
};

export type UseChatFormReturn = ReturnType<typeof useChatForm>;
