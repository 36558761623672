import {
  ChatCarousel,
  ChatCarouselItem,
  ChatStackItem,
  ChatStackItemProps,
} from '@allganize/alli-chat-base';
import { Truncate } from '@allganize/truncate';
import { carouselArrowClasses, carouselClasses } from '@allganize/ui-carousel';
import { IcGenerate } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import { ResultOf } from '@graphql-typed-document-node/core';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { gql } from '../gql';
import {
  LLMAppCarouselItem,
  LLMAppCarouselItemProps,
} from './llm-app-carousel-item';

const horizontalPadding = 12;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LLMAppCarousel_ChatFragment = gql(`
  fragment LLMAppCarousel_ChatFragment on Chat {
    ...on BotChat {
      id

      assistantRecommendedApps {
        id
        ...LLMAppCarouselItem_LLMAppFragment
      }
    }
  }
`);

export interface LLMAppCarouselProps
  extends Pick<ChatStackItemProps, 'className' | 'disablePadding'> {
  chat: ResultOf<typeof LLMAppCarousel_ChatFragment>;
  onCarouselItemClick?: LLMAppCarouselItemProps['onClick'];
}

export const LLMAppCarousel: FunctionComponent<LLMAppCarouselProps> = props => {
  const { chat, className, disablePadding, onCarouselItemClick } = props;
  const theme = useTheme();

  if (chat.__typename !== 'BotChat') {
    return null;
  }

  if (!chat.assistantRecommendedApps?.length) {
    return null;
  }

  return (
    <ChatStackItem className={className} disablePadding={disablePadding}>
      <ChatCarousel
        css={css`
          .${carouselArrowClasses.root}.${carouselArrowClasses.prev} {
            right: ${horizontalPadding + 36}px;
          }

          .${carouselArrowClasses.root}.${carouselArrowClasses.next} {
            right: ${horizontalPadding}px;
          }

          .${carouselClasses.list} {
            padding-left: ${horizontalPadding - 4}px;
          }
        `}
        title={
          <Text
            variant="title12"
            css={css`
              display: flex;
              align-items: center;
              color: ${theme.palette.foreground.secondary};
            `}
          >
            <IcGenerate />
            <Truncate clamp={1}>
              <FormattedMessage
                id="recommended-app-carousel.title"
                defaultMessage="Recommended Apps"
                description="recommended app carousel title"
              />
            </Truncate>
          </Text>
        }
      >
        {chat.assistantRecommendedApps.map((app, idx) => (
          <ChatCarouselItem key={app.id}>
            <LLMAppCarouselItem
              app={app}
              index={idx}
              onClick={onCarouselItemClick}
            />
          </ChatCarouselItem>
        ))}
      </ChatCarousel>
    </ChatStackItem>
  );
};
