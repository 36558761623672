import { ChatStackItem, ChatStackItemProps } from '@allganize/alli-chat-base';
import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { IcGenerate } from '@allganize/ui-icons';
import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css, keyframes } from '@emotion/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FragmentType, gql, readFragment } from '../gql';

const ellipsisAnimation = keyframes`
  0% {
    content: '.';
  }
  25% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '.';
  }
`;

export const GeneratingState_BotChatFragment = gql(`
  fragment GeneratingState_BotChatFragment on BotChat {
    id
    generatingState
  }
`);

export interface GeneratingStateProps extends ChatStackItemProps {
  chat: FragmentType<typeof GeneratingState_BotChatFragment>;
}

export const GeneratingState: FunctionComponent<
  GeneratingStateProps
> = props => {
  const { chat: chatProp, ...other } = props;
  const chat = readFragment(GeneratingState_BotChatFragment, chatProp);
  const theme = useTheme();

  if (!chat.generatingState) {
    return null;
  }

  if (chat.generatingState === UserQueryTypes.SearchContextStep.COMPLETE) {
    return null;
  }

  return (
    <ChatStackItem {...other}>
      <div
        css={css`
          display: inline-flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 8px;
          padding: 12px;
        `}
      >
        <IcGenerate color="primary" />

        <Text
          variant="body12"
          component="span"
          css={css`
            color: ${theme.palette.foreground.secondary};

            &:after {
              display: inline-block;
              content: '...';
              animation: ${ellipsisAnimation} 1s infinite;
              min-width: 12px;
            }
          `}
        >
          <FormattedMessage
            id={`SearchContextStep.${chat.generatingState}`}
            defaultMessage={chat.generatingState || 'Preparing'}
            description={`SearchContextStep.${chat.generatingState}`}
          />
        </Text>
      </div>
    </ChatStackItem>
  );
};
