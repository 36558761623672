import { useQuery } from '@apollo/client/react';
import { ResultOf } from '@graphql-typed-document-node/core';
import { gql } from '../gql';
import { AgentSelectOption } from './use-contact-agent-form';

export const ContactAgentForm_AgentsQuery = gql(`
  query ContactAgentForm_AgentsQuery {
    agents {
      id
      firstName
      lastName
      displayName

      avatar {
        id
        url
      }
    }
  }
`);

export const getContactAgentFormAgentSelectOption = <
  T extends NonNullable<
    ResultOf<typeof ContactAgentForm_AgentsQuery>['agents'][number]
  >,
>(
  agent: T,
) => {
  return {
    label: agent.displayName || agent.firstName || agent.lastName || '',
    value: agent.id,
    avatar: agent.avatar?.url,
  } satisfies AgentSelectOption;
};

export const useContactAgentFormAgent = () => {
  const { data, loading } = useQuery(ContactAgentForm_AgentsQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const options = data?.agents.reduce<AgentSelectOption[]>((acc, agent) => {
    if (!agent) {
      return acc;
    }

    return [...acc, getContactAgentFormAgentSelectOption(agent)];
  }, []);

  return { isLoading: loading, options };
};
