import { Skeleton } from '@allganize/ui-skeleton';
import { forwardRef } from 'react';
import { UserChatRow } from '../user-chat-row';
import { UserChatRowSkeletonProps } from './user-chat-row-skeleton.types';

export const UserChatRowSkeleton = forwardRef<
  HTMLDivElement,
  UserChatRowSkeletonProps
>((props, ref) => {
  const { avatar = false, ...other } = props;

  return (
    <UserChatRow
      avatar={avatar && <Skeleton variant="circular" width={20} height={20} />}
      {...other}
      ref={ref}
    >
      <Skeleton variant="text" textVariant="body14" width={128} />
    </UserChatRow>
  );
});
