import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { ResultOf } from '@graphql-typed-document-node/core';
import { gql } from '../gql';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IsFormValueDisabled_ChatFragment = gql(`
  fragment IsFormValueDisabled_ChatFragment on Chat {
    ...on SendFormChat {
      id
    }

    ...on UserChat {
      id
    }
  }
`);

const conversationStates: (UserQueryTypes.ConversationStateEnum | undefined)[] =
  [
    UserQueryTypes.ConversationStateEnum.WAIT_AGENT,
    UserQueryTypes.ConversationStateEnum.INTERRUPTED,
    UserQueryTypes.ConversationStateEnum.END_BY_USER,
    UserQueryTypes.ConversationStateEnum.END_OF_CHATBOT,
    UserQueryTypes.ConversationStateEnum.END_BY_AGENT,
    UserQueryTypes.ConversationStateEnum.END_OF_OBSERVER,
  ];

export interface IsFormValueDisabledOptions {
  chat: ResultOf<typeof IsFormValueDisabled_ChatFragment>;
  conversation?: Pick<UserQueryTypes.Conversation, 'state'> | null;
  lastChat?: Pick<UserQueryTypes.Chat, 'id'>;
}

export const isFormValueDisabled = ({
  chat,
  conversation,
  lastChat,
}: IsFormValueDisabledOptions): boolean => {
  if (
    chat.__typename === 'EventChat' ||
    chat.__typename === 'CustomUIChat' ||
    chat.__typename === 'FillSlotRichUXChat'
  ) {
    return true;
  }

  if (chat.__typename !== 'SendFormChat') {
    return false;
  }

  if (chat.id !== lastChat?.id) {
    return true;
  }

  return conversationStates.includes(conversation?.state);
};
