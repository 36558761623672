/* eslint-disable */
import * as UserQueryTypes from '@allganize/data-access-alli-user-query';

import { ErrorFragment } from '../fragments/error-fragment';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type StopSingleActionAppResultPublicMutationVariables =
  UserQueryTypes.Exact<{
    where: UserQueryTypes.ProjectWhereUniqueInput;
    singleActionAppResultId: UserQueryTypes.Scalars['ID']['input'];
    publicToken: UserQueryTypes.Scalars['String']['input'];
  }>;

export type StopSingleActionAppResultPublicMutation = {
  __typename: 'UserMutation';
} & {
  stopSingleActionAppResultPublic: UserQueryTypes.Maybe<
    { __typename: 'StopSingleActionAppResultPublic' } & {
      errors: UserQueryTypes.Maybe<
        Array<UserQueryTypes.Maybe<{ __typename: 'Error' } & ErrorFragment>>
      >;
    }
  >;
};

export const StopSingleActionAppResultPublicMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'StopSingleActionAppResultPublicMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectWhereUniqueInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'singleActionAppResultId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'publicToken' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stopSingleActionAppResultPublic' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'projectWhere' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'singleActionAppResultId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'singleActionAppResultId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'publicToken' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ErrorFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ErrorFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Error' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          { kind: 'Field', name: { kind: 'Name', value: 'field' } },
          { kind: 'Field', name: { kind: 'Name', value: 'info' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StopSingleActionAppResultPublicMutation,
  StopSingleActionAppResultPublicMutationVariables
>;
