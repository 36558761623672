import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { userChatRowClasses } from './user-chat-row.classes';
import { UserChatRowProps } from './user-chat-row.types';

const avatarWidth = 20;
const gap = 4;

export const UserChatRow = forwardRef<HTMLDivElement, UserChatRowProps>(
  (props, ref) => {
    const {
      align = 'right',
      'aria-describedby': ariaDescribedby,
      'aria-label': ariaLabel,
      'aria-labelledby': ariaLabelledby,
      avatar: avatarProp,
      children,
      classes,
      className,
      // @ts-expect-error internal prop for testing
      'data-mui-internal-clone-element': dataMuiInternalCloneElement,
      onBlur,
      onFocus,
      onMouseLeave,
      onMouseMove,
      onMouseOver,
      onTouchEnd,
      onTouchStart,
      title,
      ...other
    } = props;
    const theme = useTheme();

    const avatar = avatarProp && (
      <div
        css={css`
          flex-shrink: 0;
          width: ${avatarWidth}px;
          padding: 12px 0;
        `}
        className={clsx(userChatRowClasses.avatar, classes?.avatar)}
      >
        {avatarProp}
      </div>
    );

    return (
      <div
        data-testid="user-chat-row"
        css={[
          css`
            display: flex;
            align-items: flex-start;
            gap: ${gap}px;
          `,
          align === 'right' &&
            css`
              justify-content: flex-end;
            `,
        ]}
        {...other}
        className={clsx(
          userChatRowClasses.root,
          {
            [userChatRowClasses.alignLeft]: align === 'left',
            [userChatRowClasses.alignRight]: align === 'right',
          },
          classes?.root,
          {
            [classes?.alignLeft ?? '']: align === 'left',
            [classes?.alignRight ?? '']: align === 'right',
          },
          className,
        )}
      >
        {align === 'left' && avatar}

        <div
          data-mui-internal-clone-element={dataMuiInternalCloneElement}
          css={[
            css`
              padding: 12px 16px;
              border-radius: ${theme.radius.md}px;
              background-color: ${theme.palette.unstable_background.default};
              max-width: 100%;
            `,
            avatarProp &&
              css`
                max-width: calc(100% - ${avatarWidth + gap}px);
              `,
          ]}
          aria-describedby={ariaDescribedby}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledby}
          className={clsx(userChatRowClasses.content, classes?.content)}
          onBlur={onBlur}
          onFocus={onFocus}
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
          onMouseOver={onMouseOver}
          onTouchEnd={onTouchEnd}
          onTouchStart={onTouchStart}
          title={title}
          ref={ref}
        >
          {children}
        </div>

        {align === 'right' && avatar}
      </div>
    );
  },
);
