import { Text } from '@allganize/ui-text';
import { useTheme } from '@allganize/ui-theme';
import { css } from '@emotion/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { chatFormRowClasses } from './chat-form-row.classes';
import { ChatFormRowProps } from './chat-form-row.types';

const avatarWidth = 20;
const gap = 4;

export const ChatFormRow = forwardRef<HTMLDivElement, ChatFormRowProps>(
  (props, ref) => {
    const {
      align = 'right',
      avatar: avatarProp,
      children,
      classes,
      className,
      header,
      ...other
    } = props;
    const theme = useTheme();

    const avatar = avatarProp && (
      <div
        css={css`
          flex-shrink: 0;
          width: ${avatarWidth}px;
          padding: 16px 0;
        `}
        className={clsx(chatFormRowClasses.avatar, classes?.avatar)}
      >
        {avatarProp}
      </div>
    );

    return (
      <div
        data-testid="chat-form-row"
        css={[
          css`
            display: flex;
            align-items: flex-start;
            gap: ${gap}px;
          `,
          align === 'right' &&
            css`
              justify-content: flex-end;
            `,
        ]}
        {...other}
        ref={ref}
        className={clsx(
          chatFormRowClasses.root,
          {
            [chatFormRowClasses.alignLeft]: align === 'left',
            [chatFormRowClasses.alignRight]: align === 'right',
          },
          classes?.root,
          {
            [classes?.alignLeft ?? '']: align === 'left',
            [classes?.alignRight ?? '']: align === 'right',
          },
          className,
        )}
      >
        {align === 'left' && avatar}

        <div
          css={[
            css`
              background-color: ${theme.palette.unstable_background.white};
              border-radius: ${theme.radius.md}px;
              border: 1px solid ${theme.palette.border.divider.default};
              width: 292px;
              max-width: 100%;
            `,
            avatarProp &&
              css`
                max-width: calc(100% - ${avatarWidth + gap}px);
              `,
          ]}
        >
          {header && (
            <div
              data-testid="chat-form-row__header"
              css={css`
                padding: 16px 16px 0;
              `}
              className={clsx(chatFormRowClasses.header, classes?.header)}
            >
              <Text variant="title14">{header}</Text>
            </div>
          )}

          <div
            css={css`
              padding: 16px;
            `}
            className={clsx(chatFormRowClasses.content, classes?.content)}
          >
            {children}
          </div>
        </div>

        {align === 'right' && avatar}
      </div>
    );
  },
);
