import { UserQueryTypes } from '@allganize/data-access-alli-user-query';
import { Button, LoadingButton } from '@allganize/ui-button';
import { CircularProgress } from '@allganize/ui-circular-progress';
import {
  DialogActions,
  dialogClasses,
  DialogCloseButton,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@allganize/ui-dialog';
import { Text } from '@allganize/ui-text';
import { toast } from '@allganize/ui-toast';
import { InputField } from '@allganize/ui-input';
import { SelectField } from '@allganize/ui-select';
import { css } from '@emotion/react';
import {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { AppContext } from '../contexts/app-context';
import { DownloadFileContext } from '../contexts/download-file-context';
import { Dialog } from '../dialog/dialog';
import {
  DownloadFileFormValues,
  fileTypeOptions,
  getInitialFileName,
} from './download-file-form-values';
import { UIContext } from '../contexts/ui-context';

interface DownloadFileDialogProps extends DialogProps {
  onClose?: () => void;
}

export const DownloadFileDialog: FunctionComponent<DownloadFileDialogProps> = ({
  onClose,
  ...others
}) => {
  const intl = useIntl();
  const titleId = useId();
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const { modalContainer } = useContext(UIContext);
  const { data: app, mode } = useContext(AppContext);
  const { status, download, downloadFile } = useContext(DownloadFileContext);
  const [downloading, setDownloading] = useState(false);
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitted },
  } = useForm<DownloadFileFormValues>({
    defaultValues: {
      fileName: getInitialFileName(app?.name || 'file'),
      fileType: fileTypeOptions[0],
    },
  });

  const loading =
    downloading ||
    (isSubmitted && status === null) ||
    status === UserQueryTypes.PrepareFileStatusEnum.WAITING ||
    status === UserQueryTypes.PrepareFileStatusEnum.READY;

  const close = () => {
    onClose?.();
    setDownloading(false);
    setShowLoadingMessage(false);
    clearTimeout(timerRef.current);
  };

  const submit = async (values: DownloadFileFormValues) => {
    download(values);

    if (mode === 'user') {
      setDownloading(true);
      timerRef.current = setTimeout(() => setShowLoadingMessage(true), 5000); // NOTE: [design] Show loading message 5 seconds after submission
    } else {
      close();
    }
  };

  useEffect(() => {
    if (mode === 'agent' || !status) {
      return;
    }

    if (status === UserQueryTypes.PrepareFileStatusEnum.SUCCESS) {
      downloadFile();
      close();
      toast.success(
        intl.formatMessage({
          id: 'single-action.download-file-dialog.download-success-toast',
          defaultMessage: 'File has been downloaded.',
          description:
            'single action download file dialog download success toast message',
        }),
      );
    }
    if (status === UserQueryTypes.PrepareFileStatusEnum.FAILURE) {
      toast.error(
        intl.formatMessage({
          id: 'single-action.download-file-dialog.download-failure-toast',
          defaultMessage: 'Download failed please try again later.',
          description:
            'single action download file dialog download failure toast message',
        }),
      );
    }
  }, [mode, status]);

  return (
    <Dialog
      {...others}
      onClose={close}
      aria-labelledby={titleId}
      transitionDuration={0}
      css={css`
        .${dialogClasses.paper} {
          width: 480px;
        }
      `}
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(submit),
      }}
    >
      <DialogTitle id={titleId} padding="close-button">
        <FormattedMessage
          id="single-action.download-file-dialog.title"
          defaultMessage="Download file"
          description="single action download file dialog title"
        />
        <DialogCloseButton />
      </DialogTitle>
      <DialogContent
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 160px;
          gap: 16px;
        `}
      >
        {loading && (
          <Fragment>
            <CircularProgress size="xl" />
            <Text
              variant="body14"
              css={css`
                visibility: ${showLoadingMessage ? 'visible' : 'hidden'};
              `}
            >
              <FormattedMessage
                id="single-action.download-file-dialog.loading-message"
                defaultMessage="The file is being converted... It may take up to 2 minutes."
                description="single action download file dialog loading message"
              />
            </Text>
          </Fragment>
        )}
        {!loading && (
          <Fragment>
            <Controller
              control={control}
              name="fileName"
              rules={{
                required: true,
              }}
              render={({ field: { onChange, ref, ...field } }) => {
                return (
                  <InputField
                    {...field}
                    css={css`
                      margin-bottom: 16px;
                    `}
                    inputRef={ref}
                    label={
                      <FormattedMessage
                        id="single-action.download-file-dialog.name"
                        defaultMessage="Name"
                        description="single action download file dialog name input label text"
                      />
                    }
                    onChange={onChange}
                    rows={8}
                    fullWidth
                    required
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="fileType"
              rules={{
                required: true,
              }}
              render={({ field: { ref, ...field } }) => {
                return (
                  <SelectField
                    {...field}
                    label={
                      <FormattedMessage
                        id="single-action.download-file-dialog.file-type"
                        defaultMessage="File type"
                        description="single action download file dialog file type input label text"
                      />
                    }
                    selectRef={ref}
                    SelectProps={{
                      menuPortalTarget: modalContainer,
                      menuPosition: 'fixed',
                    }}
                    options={fileTypeOptions}
                    fullWidth
                    required
                  />
                );
              }}
            />
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close}>
          <FormattedMessage
            id="single-action.download-file-dialog.cancel"
            defaultMessage="Cancel"
            description="single action download file dialog cancel button text"
          />
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="filled"
          loading={loading}
        >
          <FormattedMessage
            id="single-action.download-file-dialog.confirm"
            defaultMessage="Confirm"
            description="single action download file dialog confirm button text"
          />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
