/* eslint-disable */
import * as UserQueryTypes from '@allganize/data-access-alli-user-query';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ProjectMaxKBFileCountQueryVariables = UserQueryTypes.Exact<{
  where: UserQueryTypes.ProjectWhereUniqueInput;
}>;

export type ProjectMaxKBFileCountQuery = { __typename: 'UserQuery' } & {
  project: UserQueryTypes.Maybe<
    { __typename: 'Project' } & Pick<
      UserQueryTypes.Project,
      'id' | 'maxTrialKbFileCount' | 'trialExpiredAt'
    >
  >;
};

export const ProjectMaxKBFileCountQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectMaxKBFileCountQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'where' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProjectWhereUniqueInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'where' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maxTrialKbFileCount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trialExpiredAt' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProjectMaxKBFileCountQuery,
  ProjectMaxKBFileCountQueryVariables
>;
